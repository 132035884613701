import api from '../utils/api';

export default {
  state: () => ({
    eventTypes: null,
    events: null,
  }),
  mutations: {
    setEventTypes (state, eventTypes) {
      state.eventTypes = eventTypes;
    },

    setEvents (state, events) {
      state.events = events;
    },
  },
  getters: {},
  actions: {
   async getAllEventTypes ({commit}) {
     const {data} = await api.http.get('admin/event-types')

     commit('setEventTypes', data.data)
   },

   async createEventType ({dispatch}, form) {
     const data = await api.http.post('admin/event-types', {...form})
     dispatch('getAllEventTypes')
     return data
   },

   async editEventType({dispatch}, form) {
     const {data} = await  api.http.put(`admin/event-types/${form.id}`, {...form})
     dispatch('getAllEventTypes')
     return data
   },

   async deleteEventType({dispatch}, id) {
     const {data} = await  api.http.delete(`admin/event-types/${id}`)
     dispatch('getAllEventTypes')
     return data
   },

    async getAllEvents({commit}, params) {
      const res = await api.http.get('admin/events', {params})
      commit('setEvents', res?.data)
    },

    async getArchiveEvents({commit}, params) {
      const res = await api.http.get('admin/events/archive', {params})
      commit('setEvents', res?.data)
    },

    async createEvent(_, form) {
      const {data} = await api.http.post('admin/events', {...form})

      return data
    },

    async editEvent(_, form) {
      const {data} = await api.http.put(`admin/events/${form.id}`, {...form})

      return data
    },

    async getEventById(_, eventId) {
      const {data} = await api.http.get(`admin/events/${eventId}`)

      return data.data
    },

    async deleteEvent({dispatch}, id) {
      const {data} = await  api.http.delete(`admin/events/${id}`)
      dispatch('getAllEvents')
      return data
    },

    async restoreEvent(_, id){
      const {data} = await api.http.get(`admin/events/restore/${id}`)
      return data
    },
  },
  namespaced: true,
}
