<template>
  <router-link class="wish-info" :to="{name: 'ViewWish', params: {id: wish.id}}">
    <div>
      {{ wish.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'WishInfo',
  props: {
    wish: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="sass" scoped>

.wish-info
  display: flex
  align-items: center
</style>
