<template>
  <div>
    <PageHeader link-name="Назад" link-path="WishesList" page-name="Модерирование Тендеров">
      <template>
        <div class="title-bar__button-wrapper">
          <router-link class="button-wrapper-link" :to="{name: 'WishesList'}">
            <el-button type="gray">
              <i class="fas fa-list" /> Список
            </el-button>
          </router-link>

          <router-link class="button-wrapper-link" :to="{name: 'LogsList', params: {id: wish && wish.id, subject: 'wish'}}">
            <el-button type="info">
              <i class="fas fa-info-circle" /> Действия над Желанием
            </el-button>
          </router-link>

          <el-button type="success" @click="onSaveWish">
            Сохранить
          </el-button>
        </div>
      </template>
    </PageHeader>
    <MainPanel v-loading="loading" class="panel--mb">
      <table v-if="wish" class="comparing-table">
        <tr>
          <th>
            <div class="comparing-columns-heading">
              Текущая версия
            </div>
          </th>
          <th v-if="wish.prev_version">
            <div class="comparing-columns-heading">
              Предыдущая версия
            </div>
          </th>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Название
            </div>
            <EditableText v-model="form.name" />
          </td>
          <td v-if="wish.prev_version">
            <div class="comparing-table__label">
              Название
            </div>
            {{ wish.prev_version.data.name }}
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Описание
            </div>
            <EditableText v-model="form.description" rows="10" type="textarea" />
          </td>
          <td v-if="wish.prev_version">
            <div class="comparing-table__label">
              Описание
            </div>
            {{ wish.prev_version.data.description }}
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Основная Категория
            </div>

            <CategorySelector
              v-model="form.category"
            />
            <div class="comparing-table__label">
              Выбрать Дополнителную Категорию
            </div>
            <div
              v-if="wish.additional_categories.length < 10"
              class="additional-categories"
            >
              <CategorySelector
                v-model="additionalCategory"
                class="additional-categories__block"
                :make-disable-categories="false"
                placeholder="Выберите дополнительную категорию"
              />
              <el-button @click="addAdditionalCategory">Добавить</el-button>
            </div>
            <br>
            <div
              v-for="(id, index) in form.additional_categories"
              :key="index"
              class="additional-categories__list"
            >
              <CategorySelector
                v-model="form.additional_categories[index]"
                :disabled="true"
                style="width: 100%"
              />
              <el-button
                icon="el-icon-delete"
                size="mini"
                type="danger"
                @click="toDeleteAdditionalCategory(id)"
              />
            </div>
          </td>

          <td v-if="wish.prev_version">
            <div class="comparing-table__label">
              Категории
            </div>
            <div v-if="wish.prev_version.category">
              {{ wish.prev_version.category.name }}
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="form.images"
              removing-allowed
              @change-images="changeImages"
            />
          </td>
          <td v-if="wish.prev_version">
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="wish.prev_version.data.images"
              @change-images="changeImages"
            />
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ wish.video_link || '---' }}
          </td>
          <td v-if="wish.prev_version && wish.prev_version.data.video_link">
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ wish.prev_version.data.video_link || '---' }}
          </td>
        </tr>
      </table>
      <List :list-offers-for-wishes="listOffersForWishes" />
    </MainPanel>

    <MainPanel v-loading="loading">
      <table v-if="wish" class="key-values-table">
        <tr v-if="wish.url">
          <td>Ссылка на тендер</td>
          <td>
            <a :href="wish.url" target="_blank">
              {{ wish.url }}
            </a>
          </td>
        </tr>

        <tr v-else>
          <td>id</td>
          <td>{{ wish.id }}</td>
        </tr>

        <tr v-if="wish.created_at">
          <td>Дата создания</td>
          <td>{{ wish.created_at }}</td>
        </tr>

        <tr v-if="wish.price">
          <td>Цена</td>
          <td>{{ wish.price }} {{ getCurrencyByKey(wish.currency) }}</td>
        </tr>

        <tr v-if="wish.condition">
          <td>Состояние</td>
          <td>{{ getWishConditionByKey(wish.condition) }}</td>
        </tr>

        <tr v-if="wish.contacts.phone">
          <td>Номер телефона</td>
          <td>{{ wish.contacts.phone }}</td>
        </tr>

        <tr v-if="wish.city">
          <td>Населённый пункт</td>
          <td>{{ wish.city.name }}</td>
        </tr>

        <tr v-if="wish.city">
          <td>Область</td>
          <td>{{ wish.city.region_name }}</td>
        </tr>

        <tr v-if="wish.complaints_count">
          <td>Количество жалоб</td>
          <td>{{ wish.complaints_count }}</td>
        </tr>

        <tr>
          <td>Пользователь</td>
          <td>
            <UserCard :user="wish.user" />
          </td>
        </tr>

        <tr v-if="wish.city">
          <td>Статус</td>
          <td>
            <el-tag
              class="type-tag"
              size="small"
              :type="getStatusTagType(wish.state)"
            >
              {{ wish.state.replace('_', ' ') }}
            </el-tag>
          </td>
        </tr>

        <tr>
          <td>Подтверждение</td>
          <td>
            <el-button-group>
              <el-button
                :disabled="!wish.actions.discard"
                size="small"
                :type="wish.state === 'DISCARDED' ? 'danger' : ''"
                @click="$refs.checkReasonModal.open()"
              >
                Нет
              </el-button>
              <el-button
                :disabled="!wish.actions.approve"
                size="small"
                :type="wish.state === 'CHECKED' ? 'success' : ''"
                @click="onApproveWish"
              >
                Да
              </el-button>
            </el-button-group>
          </td>
        </tr>
        <tr>
          <td />
          <td><el-button @click="deleteThisWish">Удалить</el-button></td>
        </tr>
      </table>
    </MainPanel>

    <CheckReasonModal ref="checkReasonModal" @check="onDiscardWish" />
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions, mapGetters} from 'vuex'
import EditableText from '@/components/common/EditableText'
import ImageViewer from '@/components/common/ImageViewer'
import CheckReasonModal from '@/components/wishes/CheckReasonModal'
import CategorySelector from '@/components/wishes/CategorySelector'
import UserCard from '@/components/user-card/UserCard';
import List from '@/components/offers/List';
import PageHeader from '@/components/common/PageHeader';


export default {
  metaInfo: {
    title: 'Модерирование желания',
  },
  components: {List, CategorySelector, CheckReasonModal, ImageViewer, EditableText, MainPanel, UserCard, PageHeader},
  data() {
    return {
      wishId: null,
      wish: null,
      loading: false,
      timer: null,
      form: {
        name: '',
        description: '',
        categories: [],
        additional_categories: [],
        category_id: '',
      },
      additionalCategory:'',
      listOffersForWishes: [],
    }
  },
  computed: {
    ...mapGetters('value-objects', [
      'getWishConditionByKey',
      'getStatusTagType',
      'getCurrencyByKey',
    ]),
  },
  async created() {
    this.wishId = this.$route.params.id
    try {
      try {
        await this.takeMutex(this.wishId)
      } catch (e) {
        this.$alert('Этот тендер сейчас редактируется дргуим пользователем. Попробуйте позже');

        throw e;
      }

      await Promise.all([
        this.getWish(),
        this.fetchWishConditions(),
        this.fetchCurrencies(),
      ])
      this.fillForm()
      this.setMutexLoop()
    } catch (e) {
      this.$router.back()
    }
    this.listOffersForWishes = await this.fetchOffersForWishes(this.wishId);
    if(this.wish.category) {
      this.form.category_id = await this.wish.category.id
    }
    this.wish.additional_categories.forEach(el => {
    this.form.additional_categories.push(el.public_id)
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
    try {
      this.leaveMutex(this.wishId)
    } catch (e) {

    }
  },
  methods: {
    ...mapActions('wishes', [
      'fetchWish',
      'approveWish',
      'discardWish',
      'takeMutex',
      'leaveMutex',
      'updateWish',
      'deleteWish',
    ]),
    ...mapActions('offers', [
      'fetchOffersForWishes',
    ]),
    ...mapActions('value-objects', [
      'fetchWishConditions',
      'fetchCurrencies',
    ]),
    async getWish() {
      this.wish = await this.fetchWish(this.wishId)
    },
    async onApproveWish() {
      try {
        await this.approveWish(this.wish.id)
        await this.getWish()
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },
    async onDiscardWish(form) {
      await this.discardWish({
        id: this.wish.id,
        form,
      })
      await this.getWish()
    },
    async onSaveWish() {
      try {
        await this.updateWish({
          id: this.wish.id,
          form: this.form,
        })
       await this.$router.go(0)
        this.$message.success('Желание успешно сохранено')
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },
    fillForm() {
      this.form.name = this.wish.name
      this.form.description = this.wish.description
      this.form.images = this.wish.images
      if(this.wish.category) {
        this.form.category_id = this.wish.category.id
      }
      if(this.wish.categories_path.length === 0) return
      this.form.category = this.wish.categories_path[this.wish.categories_path.length - 1].id
    },
    changeImages(res) {
      const clone = {...this.form}
      clone.images = res
      this.form = clone
    },
    setMutexLoop() {
      this.timer = setInterval(() => {
        this.takeMutex(this.wish.id)
      }, 60000)
    },
    async deleteThisWish()
    {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Вы дейсвтительно хотите удалить желание? Будут удалены также все предложения.', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {
      }

      if (!confirmation) {
        return
      }

      try {
        await this.deleteWish(this.wish.id)
        this.$message.success('Желание успешно удалено')
        await this.$router.push({name: 'WishesList'})
      } catch (e) {
        this.$message.error('Не получилось удалить желание.')
      }
    },
    addAdditionalCategory(){
      if(!this.additionalCategory) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Нужно выбрать Категорию',
        });
        return
      }
      this.form.additional_categories.push(this.additionalCategory[this.additionalCategory.length - 1])
      this.additionalCategory = ''
    },

    toDeleteAdditionalCategory(id){
      this.form.additional_categories.splice(id, 1)
    },

  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.wish-info-label
  margin-bottom: 10px
  font-weight: bold

.wish-info-wrapper
  display: flex

  > *
    margin-right: 50px

.panel--mb
  margin-bottom: 25px

.comparing-table__label
  margin-bottom: 4px
  font-weight: 600

.additional-categories
  display: flex
  align-items: center

.additional-categories__block
  width: 100%
  margin-right: 10px


.additional-categories__list
  display: flex
  padding: 10px
  gap: 10px
  width: 70vw


</style>
