import AdminsListPage from '@/pages/admin-management/ListPage'
import createUpdateAdminPage from '@/pages/admin-management/CreateUpdatePage'

export default [
  {
    path: '/admin-management/list',
    name: 'AdminsList',
    component: AdminsListPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['admin-users.list'],
    },
  },
  {
    path: '/admin-management/create',
    name: 'CreateAdmin',
    component: createUpdateAdminPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['admin-users.list'],
    },
  },
  {
    path: '/admin-management/update/:id',
    name: 'UpdateAdmin',
    component: createUpdateAdminPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['admin-users.list'],
    },
  },
]
