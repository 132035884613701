<template>
  <div>
    <PageHeader page-name="Категории тикетов">
      <template>
        <router-link :to="{name: 'CreateTicketCategory'}">
          <el-button type="primary">
            Создать категорию
          </el-button>
        </router-link>
      </template>
    </PageHeader>

    <MainPanel>
      <List v-loading="loading" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import List from '@/components/help-center/ticket-categories/List'
import MainPanel from '@/components/common/MainPanel'
import PageHeader from '@/components/common/PageHeader';


export default {
  components: {MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Категории тикетов',
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.getModel()
    this.loading = false
  },
  methods: {
    ...mapActions('help-center', {
      getModel: 'fetchTicketCategories',
    }),
  },
}
</script>
