import api from '@/utils/api'

export default {
  state: () => ({
    contractors: null,
  }),
  mutations: {
    setContractors(state, payload) {
      state.contractors = payload
    },
  },
  getters: {},
  actions: {
    async fetchContractors({commit}, params) {
      if (params?.search === '') {
        delete params.search
      }
      const res = await api.http.get('/admin/contractors', {params})
      commit('setContractors', res?.data)
    },
    async fetchContractor(_, id) {
      const res = await api.http.get(`admin/contractors/${id}`)
      return res?.data?.data
    },
    async changeContractorState(_, id) {
      const res = await api.http.get(`admin/contractors/approve/${id}`)
      return res?.data?.data
    },
    async deleteContractor({dispatch}, id) {
      const res = await api.http.delete(`admin/contractors/${id}`)
      dispatch('fetchContractors')
      return res?.data?.data
    },
    async updatePhone({dispatch}, {phone, id}) {
      const res = await  api.http.put(`admin/contractors/${id}/phone`, {phone})
      dispatch('fetchContractors')
      return res?.data?.data
    },
    async fetchContractorCategories() {
      const res = await api.http.get('tree-categories')
      return res?.data?.data
    },
    async updateContractorCategory(_, { contractorId, categoryId }) {
      const res = await api.http.put(`admin/contractors/${contractorId}/category`, {category_id: categoryId})
      return res?.data?.data
    },
  },
  namespaced: true,
}
