import api from '@/utils/api'

export default {

  actions: {
    async fetchStatistics(_,{dateFrom, dateTo}) {
      const res = await api.http.get(`admin/statistic?date_from=${dateFrom}&date_to=${dateTo}`)
      return res
    },

  },
  namespaced: true,
}

