import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import './assets/sass/_element-variables.sass'
//import 'element-ui/lib/theme-chalk/index.css'
import ElementUILocale from 'element-ui/lib/locale/lang/en'
import vueDebounce from 'vue-debounce'
import VueMeta from 'vue-meta'


Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(ElementUI, {locale: ElementUILocale})
Vue.use(vueDebounce, {
  listenTo: 'input',
})
Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
