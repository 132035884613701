<template>
  <div>
    <PageHeader page-name="Тендеры">
      <template>
        <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-loading="loading" @sort="doSort" />

      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import List from '@/components/wishes/List'
import MainPanel from '@/components/common/MainPanel'
import FilterPanel from '@/components/common/FilterPanel'
import Filters from '@/components/wishes/Filters'
import PageHeader from '@/components/common/PageHeader';


export default {
  components: {Filters,  MainPanel, List, PageHeader, FilterPanel},
  metaInfo: {
    title: 'Желания',
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('wishes', [
      'wishes',
    ]),
    meta() {
      return this.wishes?.meta
    },
  },
  mounted() {
    this.getWishes()
  },
  methods: {
    ...mapActions('wishes', [
      'fetchWishes',
    ]),
    async getWishes() {
      this.loading = true
      await this.fetchWishes(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getWishes()
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.page = 1
      this.getWishes()
    },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getWishes()
    },
  },
}
</script>
