<template>
  <el-table
    :data="list && list.data"
    :default-sort="{prop: 'created_at', order: 'descending'}"
    stripe
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column label="Имя">
      <template slot-scope="scope">
        <UserCard :user="scope.row" />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label=" Дата регистрации пользователя "
      prop="created_at"
      sortable="custom"
    />

    <el-table-column
      align="center"
      label="Email"
      min-width="100"
      prop="email"
    />

    <el-table-column
      align="center"
      label="Телефон"
      prop="phone"
    />

    <el-table-column
      align="center"
      label="Дата последнего визита"
      prop="last_visited"
    />

    <el-table-column
      align="center"
      label="Дата и время завершения бана"
      prop="ban.banned_at"
    />

    <el-table-column
      align="center"
      label="Тип"
      width="200"
    >
      <template slot-scope="scope">
        <el-tag
          class="type-tag"
          size="small"
          :type="scope.row.type === 'business' ? 'info' : 'warning'"
        >
          {{ scope.row.type }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapState} from 'vuex'
import UserCard from '@/components/user-card/UserCard';



export default {
  name: 'List',
  components: {UserCard},
  computed: {
    ...mapState('users', {
      list: 'users',
    }),
  },
  methods: {
    onView(id) {
      this.$router.push({name: 'ViewUser', params: {id}})
    },
    onSortChange(val) {
      if (val.prop === 'created_at') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'oldest' : 'newest',
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"


.type-tag
  text-transform: uppercase

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0

</style>
