import ProductsListPage from '@/pages/products/ListPage.vue'
import ViewProductPage from '@/pages/products/ViewPage'

export default [
  {
    path: '/products/list',
    name: 'ProductsList',
    component: ProductsListPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['products.list'],
    },
  },
  {
    path: '/products/view/:id',
    name: 'ViewProducts',
    component: ViewProductPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['products.view'],
    },
  },
]
