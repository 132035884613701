<template>
  <div>
    <TopicForm />
  </div>
</template>

<script>


import TopicForm from '../../components/topics/TopicForm.vue';

export default {
  components: {TopicForm},
}
</script>
