import api from '@/utils/api'

export default {
  actions: {
    async getProblemTickets(_, filter) {
      try {
        const { data } = await api.http.get('admin/helpcenter/problem-tickets', filter)
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getProblemTicketById(_, ticketId) {
      try {
        const { data } = await api.http.get(`admin/helpcenter/problem-tickets/${ticketId}`)
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    },

    async closeProblemTicket(_, ticketId) {
      try {
        await api.http.patch(`admin/helpcenter/problem-tickets/${ticketId}/close`)
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async openProblemTicket(_, ticketId) {
      try {
        await api.http.patch(`admin/helpcenter/problem-tickets/${ticketId}/open`)
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async deleteProblemTicket(_, ticketId) {
      try {
        await api.http.delete(`admin/helpcenter/problem-tickets/${ticketId}`)
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
  namespaced: true,
}
