<template>
  <el-table
    :data="list && list.data"
    style="width: 100%"
  >
    <el-table-column
      align="center"
      label="#"
      type="index"
      width="50"
    />

    <el-table-column
      align="center"
      label="Навыки"
      prop="title"
      width="240"
    />

    <el-table-column
      align="left"
      label="Связанные категории"
      prop="categories"
    >
      <template slot-scope="scope">
        {{ scope.row.categories.join(', ') }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  name: 'TagsPage',
  computed: {
    ...mapState('tags', {
      list: state => state.tags,
    }),
  },
  created() {
    this.fetchTags()
  },
  methods: {
    ...mapActions('tags', [
      'fetchTags',
    ]),
  },
}
</script>

<style lang="sass" scoped>

</style>
