import ListPage from '@/pages/logs/ListPage';

export default [
  {
    path: '/logs/:subject/:id',
    name: 'LogsList',
    component: ListPage,
    meta: {
      requiresAuth: true,
    },
  },
]
