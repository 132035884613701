<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-8">
        <el-form-item label="Вопрос" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
      </div>
      <div class="col-md-4">
        <el-form-item label="Порядок" prop="order">
          <el-input-number v-model="form.order" :min="1" />
        </el-form-item>
      </div>
    </div>

    <el-form-item label="Ответ" prop="answer">
      <Redactor v-model="form.answer" />
    </el-form-item>

    <div class="text-right">
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Сохранить
      </el-button>
    </div>
  </el-form>
</template>

<script>
import Redactor from '@/components/common/WysiwygRedactor'
import {mapActions} from 'vuex'

export default {
  name: 'FAQForm',
  components: {Redactor},
  data() {
    return {
      form: {
        name: '',
        answer: '',
        order: 1,
      },
      rules: {
        name: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        answer: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateFAQ'
    },
  },
  created() {
    this.getParamsFromRouter()
  },
  methods: {
    ...mapActions('help-center', [
      'getFAQItem',
      'createFAQItem',
      'updateFAQItem',
    ]),
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.isUpdate) {
            await this.updateFAQItem(this.form)
          } else {
            await this.createFAQItem(this.form)
          }
          await this.$router.push({name: 'FAQList'})
        } else {
          return false
        }
      })
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id
      if (id) {
        const model = await this.getFAQItem(id)
        this.fillForm(model)
      }
    },
    fillForm(model) {
      this.form = {...this.form, ...model}
    },
  },
}
</script>
