<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <el-form-item label="Название" prop="name">
      <el-input v-model="form.name" />
    </el-form-item>
  </el-form>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      form: {
        name: '',
      },
      rules: {
        name: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateTicketCategory'
    },
  },
  created() {
    this.getParamsFromRouter()
  },
  methods: {
    ...mapActions('help-center', [
      'getTicketCategory',
      'createTicketCategory',
      'updateTicketCategory',
    ]),
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.isUpdate) {
            await this.updateTicketCategory(this.form)
          } else {
            await this.createTicketCategory(this.form)
          }
          await this.$router.push({name: 'TicketCategoriesList'})
        } else {
          return false
        }
      })
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id
      if (id) {
        const model = await this.getTicketCategory(id)
        this.fillForm(model)
      }
    },
    fillForm(model) {
      this.form = {...this.form, ...model}
    },
  },
}
</script>
