import FAQList from '@/pages/help-center/faq/ListPage'
import createUpdateFAQ from '@/pages/help-center/faq/CreateUpdatePage'
import ticketCategoriesList from '@/pages/help-center/ticket-category/ListPage'
import createUpdateTicketCategory from '@/pages/help-center/ticket-category/CreateUpdatePage'
import ticketList from '@/pages/help-center/tickets/ListPage'
import problemTicketList from '@/pages/help-center/tickets-problem/ListPage'

export default [
  {
    path: '/help-center/faq/list',
    name: 'FAQList',
    component: FAQList,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['helpcenter.faq-list'],
    },
  },
  {
    path: '/help-center/faq/create',
    name: 'CreateFAQ',
    component: createUpdateFAQ,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['helpcenter.faq-create'],
    },
  },
  {
    path: '/help-center/faq/update/:id',
    name: 'UpdateFAQ',
    component: createUpdateFAQ,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['helpcenter.faq-update'],
    },
  },
  {
    path: '/help-center/ticket-category/list',
    name: 'TicketCategoriesList',
    component: ticketCategoriesList,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['helpcenter.ticket-category-list'],
    },
  },
  {
    path: '/help-center/ticket/list',
    name: 'TicketList',
    component: ticketList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help-center/tickets-problem/list',
    name: 'problemTicketList',
    component: problemTicketList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help-center/ticket-category/create',
    name: 'CreateTicketCategory',
    component: createUpdateTicketCategory,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['helpcenter.ticket-category-create'],
    },
  },
  {
    path: '/help-center/ticket-category/update/:id',
    name: 'UpdateTicketCategory',
    component: createUpdateTicketCategory,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['helpcenter.ticket-category-update'],
    },
  },
]
