<template>
  <el-table :data="data" stripe style="width: 100%">
    <el-table-column label="Пользователь" width="250">
      <template slot-scope="scope">
        <router-link v-if="scope.row.user.user" class="name-wrapper" :to="{name: 'ViewUser', params: {id: scope.row.user.user}}">
          <img
            alt="Avatar"
            class="avatar"
            :class="{'avatar--business': scope.row.user.is_business}"
            :src="scope.row.user.avatar"
          >
          {{ scope.row.user.show_name }}
        </router-link>
        <div v-else>{{ scope.row.user }}</div>
      </template>
    </el-table-column>
    <el-table-column label="Статус" prop="status" />
    <el-table-column label="Создано" prop="created_at" />
    <el-table-column label="Категория" prop="category">
      <template slot-scope="scope">
        {{ scope.row.category.name }}
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>

export default {
  name: 'List',
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    onView(id) {
      this.$router.push({name: 'ViewTicketPage', params: {id}})
    },
  },
}
</script>
