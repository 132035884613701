<template>
  <div class="name-wrapper" :class="{'big-name': isBig}">
    <img
      v-if="user.avatar"
      alt="Avatar"
      class="avatar user-info__avatar"
      :class="{'avatar--business':user.is_business, 'avatar--ban': user.is_ban, 'avatar-premium': user.has_vip_frame, 'big-avatar': isBig, 'big-avatar--business': isBig && user.is_business}"
      :src="user.avatar"
    >
    <i v-else class="fas fa-user avatar avatar-default " :class="{'avatar--business':user.is_business, 'avatar--ban': user.is_ban, 'avatar-premium': user.has_vip_frame, 'big-avatar': isBig, 'big-avatar--business': isBig && user.is_business}" />
    {{ user.show_name }}
  </div>
</template>

<script>


export default {
  name: 'UserAvatar',
  props:{
    user:{
      type: Object,
      required:true,
    },
    isBig:{
      type:Boolean,
      required: false,
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.big-avatar
  height: 90px
  width: 90px
  margin: 6px 20px 6px 6px
  border: 6px solid $color-bg
  box-shadow: 0 0 0 6px $color-text-accent-customer
  border-radius: 50%

  &.big-avatar--business
   border: 6px solid #ffffff
   box-shadow: 0 0 0 6px $color-text-accent-business

.big-name
  font-size: 28px
  font-weight: bold



</style>
