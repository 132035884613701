<template>
  <div>
    <PageHeader page-name="Статистика" />
    <List />
  </div>
</template>

<script>

import List from '@/components/statistics/List'
import PageHeader from '@/components/common/PageHeader';

export default {
  components: {List, PageHeader},
}
</script>

<style scoped>

</style>
