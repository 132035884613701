<template>
  <MainPanel v-loading="loading">
    <UserAvatar :is-big="true" style="margin-bottom: 20px" :user="trashOffer.user" />
    <table v-if="trashOffer" class="key-values-table">
      <tr>
        <td>id</td>
        <td>{{ trashOffer.id }}</td>
      </tr>
      <tr>
        <td>Название</td>
        <td>{{ trashOffer.name }}</td>
      </tr>
      <tr>
        <td>Категория</td>
        <td v-if="trashOffer.category">
          {{ trashOffer.category.name }}
        </td>
        <td v-else>{{ 'Категория не выбранна' }}</td>
      </tr>
      <tr>
        <td>Дата создания</td>
        <td>{{ trashOffer.created_at }}</td>
      </tr>
      <tr>
        <td>Дата удаления</td>
        <td>{{ trashOffer.deleted_at }}</td>
      </tr>
      <tr>
        <td>Город</td>
        <td>{{ trashOffer.city.name }}</td>
      </tr>
      <tr>
        <td>Область</td>
        <td>{{ trashOffer.city.region_name }}</td>
      </tr>
      <tr>
        <td>Описание</td>
        <td>{{ trashOffer.description }}</td>
      </tr>
      <tr>
        <td>Количество жалоб</td>
        <td>{{ trashOffer.complaints_count }}</td>
      </tr>
      <tr>
        <td>Состояние</td>
        <td>
          {{ trashOffer.condition }}
        </td>
      </tr>
    </table>
  </MainPanel>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import {mapActions} from 'vuex';

export default {
  name: 'ViewPage',
  components: {MainPanel, UserAvatar},

  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
      trashId: null,
      trashOffer:null,
    }
  },

  async created() {
    this.trashId = this.$route.params.id
    this.trashOffer = await this.fetchOfferTrash(this.trashId)
  },

  methods:{
    ...mapActions('offersTrash', [
      'fetchOfferTrash',
    ]),
  },
}
</script>

<style scoped>

</style>
