<template>
  <div>
    <CoolLightBox
      :index="index"
      :items="images.map(x=>x.path)"
      @close="index = null"
    />

    <div class="images-wrapper">
      <div
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        class="image"
      >
        <div
          class="image-inner"
          :style="{ backgroundImage: 'url(' + image.path + ')' }"
          @click="index = imageIndex"
        />
        <i
          v-if="removingAllowed"
          class="delete-button fas fa-trash-alt"
          @click="deleteImage(image.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'ImageViewer',
  components: {
    CoolLightBox,
  },
  props: {
    images: {
      type: Array,
      default() {
        return []
      },
    },
    removingAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      index: null,
    }
  },
  methods: {
    async deleteImage(id) {
      try {
        const res = await this.$confirm('Вы действительно хотите удалить это изображение?')
        if (res) {
          this.$emit('change-images', this.images.filter(x => x.id !== id))
        }
      } catch {
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.images-wrapper
  margin: 0 -5px
  display: flex
  flex-wrap: wrap

.image
  width: 100px
  height: 100px
  margin: 5px
  position: relative

.image-inner
  cursor: pointer
  background-size: cover
  background-position: center
  position: absolute
  border-radius: 5px
  top: 0
  bottom: 0
  right: 0
  left: 0

  &:hover
    opacity: 0.8

.delete-button
  color: $color-text-accent-customer
  position: absolute
  top: 8px
  right: 8px
  font-size: 20px
  line-height: 20px
  cursor: pointer

  &:hover
    opacity: 0.6

</style>
