import api from '@/utils/api'

export default {
  actions: {
    async fetchWishesTrash(_, params) {
      if(params.search){
        params.search = params.search.trim()
      }
      if (params.search === '') {
        delete params.search
      }
      const res = await api.http.get('admin/wishes/trash', {params})
      return res?.data
    },

    async fetchWishTrash(_, id) {
      const res = await api.http.get(`admin/wishes/trash/${id}`)
      return res?.data?.data
    },
  },
  namespaced: true,
}
