<template>
  <div>
    <PageHeader page-name="FAQ">
      <template>
        <router-link :to="{name: 'CreateFAQ'}">
          <el-button type="primary">
            Создать Вопрос/Ответ
          </el-button>
        </router-link>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-loading="loading" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import List from '@/components/help-center/faq/List'
import MainPanel from '@/components/common/MainPanel'
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'FAQListPage',
  components: {MainPanel, List, PageHeader},
  metaInfo: {
    title: 'FAQ',
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.getModel()
    this.loading = false
  },
  methods: {
    ...mapActions('help-center', {
      getModel: 'fetchFAQList',
    }),
  },
}
</script>
