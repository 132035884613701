<template>
  <router-link class="name-wrapper" :to="{name: 'ViewClient', params: {id: client.id}}">
    <UserAvatar :user="client" />
  </router-link>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar'


export default {
  name: 'ClientCard',
  components:{UserAvatar},
  props:{
    client:{
      type:[Object, null],
      required:true,
    },
  },
}

</script>

<style scoped>

</style>
