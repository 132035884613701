import OffersTrash from '@/pages/offers-trash/ListPage'
import ViewOfferPage from '@/pages/offers-trash/ViewPage'



export default [
  {
    path: '/offers/trash',
    name: 'OffersTrash',
    component: OffersTrash,

  },
  {
    path: '/offers/trash/:id',
    name: 'ViewOfferTrash',
    component: ViewOfferPage,
  },
]
