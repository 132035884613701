<template>
  <div v-loading="loading">
    <div class="title-bar">
      <h1>Просмотр чата <span v-if="title">"{{ title }}"</span></h1>

      <div class="title-bar__button-wrapper">
        <router-link :to="{name: 'ViewComplaint', params: {id: complaintId}}">
          <el-button type="gray">
            <i class="fas fa-list" /> Назад к жалобе
          </el-button>
        </router-link>
      </div>
    </div>

    <MainPanel>
      <div v-if="messages" class="chat">
        <p class="chat__title">
          Сообщения
        </p>
        <div ref="chatContainer" class="chat__container">
          <Message
            v-for="message of messages"
            :key="message.id"
            :message="message"
          />
        </div>
      </div>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex'
import Message from '@/components/help-center/tickets/Message'

export default {
  name: 'ViewChat',
  components: {MainPanel, Message},
  props: {
    complaintId: {
      type: String,
      required: true,
    },
    chatId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      loading: true,
      title: null,
      messages: [],
    }
  },
  async created() {
    let data = await this.getDialogsInChatsBetweenTwoUsers(this.chatId)

    this.title = data.chat_title
    this.messages = data.data.reverse()
    this.loading = false

    if (!this.messages.length) {
      return
    }

    //TODO эти грязные делишки должны быть сделаны на сервере
    let firstSender = this.messages[0].sender_id
    this.messages.forEach(function(message) {
      message.is_mine = message.sender_id === firstSender
      message.text = message.message
      message.media = []
      if (message.file) {
        message.media = [
          {
            id: message.id,
            path: message.file.mini,
          },
        ]
      }
    })
  },
  methods: {
    ...mapActions('complaints', [
      'getDialogsInChatsBetweenTwoUsers',
    ]),
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.panel--mb
  margin-bottom: 25px

.chat__container
  height: 700px
  overflow: scroll

.chat__title
  font-size: 26px
  font-weight: bold

.chat__controls
  display: grid
  grid-template-columns: 4fr 80px 1fr
  grid-gap: 10px
  padding-top: 20px

.chat__file
  background-color: $color-info
  display: flex
  align-items: center
  justify-content: center
  border-radius: 4px
  color: $color-foreground-text
  height: 100%
  cursor: pointer
.chat__file-icon
  font-size: 20px
.chat__file-input
  display: none
.key-values-table
  width: 100%
</style>
