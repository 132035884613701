import EventTypesPage from '../pages/events/EventTypesPage.vue';
import AllEventsPage from '../pages/events/AllEventsPage.vue';
import EventCreatePage from '../pages/events/EventCreatePage.vue';
import EditEventPage from '../pages/events/EditEventPage.vue';
import EventsArchivePage from '../pages/events/EventsArchivePage.vue';
export default [
  {
    path: '/events/eventTypes',
    name: 'EventTypesPage',
    component: EventTypesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/list',
    name: 'AllEventsPage',
    component: AllEventsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/archive',
    name: 'EventsArchivePage',
    component: EventsArchivePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/create',
    name: 'EventCreatePage',
    component: EventCreatePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/edit/:id',
    name: 'EditEventPage',
    component: EditEventPage,
    meta: {
      requiresAuth: true,
    },
  },
]
