<template>
  <div>
    <PageHeader page-name="Категории" />
    <MainPanel>
      <el-tree v-loading="!categories.length" :data="categories">
        <span slot-scope="{ node, data }" class="category-node">
          <span>
            {{ data.name }}
            ({{ data.name_ua }})
          </span>

          <a :href="data.url" target="_blank">На сайт</a>
        </span>
      </el-tree>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions, mapGetters, mapState} from 'vuex'
import PageHeader from '@/components/common/PageHeader';


export default {
  name: 'TreePage',
  components: {MainPanel, PageHeader},
  computed: {
    ...mapGetters('wishes/categories', [
      'getCategoriesForSelector',
    ]),
    ...mapState('wishes/categories', [
      'categories',
    ]),
  },
  created() {
    this.fetchCategories()
  },
  methods: {
    ...mapActions('wishes/categories', [
      'fetchCategories',
    ]),
  },
}
</script>

<style scoped>
.category-node {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}
</style>
