import usersListPage from '@/pages/users/ListPage'
import viewUserPage from '@/pages/users/ViewPage'

export default [
  {
    path: '/users/list',
    name: 'UsersList',
    component: usersListPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['users.list'],
    },
  },
  {
    path: '/users/view/:id',
    name: 'ViewUser',
    component: viewUserPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['users.view'],
    },
  },
]
