import api from '@/utils/api'

export default {
  actions: {
    async fetchOffersTrash(_, params) {
      if(params.search){
        params.search = params.search.trim()
      }
      if (params.search === '') {
        delete params.search
      }
      const res = await api.http.get('admin/offers/trash', {params})
      return res?.data
    },

    async fetchOfferTrash(_, id) {
      const res = await api.http.get(`admin/offers/trash/${id}`)
      return res?.data?.data
    },
  },
  namespaced: true,
}
