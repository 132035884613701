<template>
  <div>
    <el-drawer
      direction="ltr"
      size="70%"
      :title="formTitle"
      :visible.sync="isDrawerOpen"
      @closed="onClosed"
    >
      <div class="internal-form">
        <el-form
          ref="form"
          label-position="top"
          label-width="220px"
          :model="form"
        >
          <el-form-item label="Имя Юзера">
            <el-select
              v-model="form.user_id"
              placeholder="Имя"
            >
              <el-option
                v-for="item in internalUsers"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
            {{ $_ErrorMixin_getError('user_id') }}
          </el-form-item>

          <el-form-item label="Название тендера">
            <el-input v-model="form.name" />
            {{ $_ErrorMixin_getError('name') }}
          </el-form-item>

          <el-form-item label="Описание">
            <el-input v-model="form.text" type="textarea" />
            {{ $_ErrorMixin_getError('text') }}
          </el-form-item>

          <el-form-item label="Категория">
            <CategorySelector
              v-model="category"
            />
            {{ $_ErrorMixin_getError('category_id') }}
          </el-form-item>

          <el-form-item label="Дополнительная Категория">
            <div
              class="additional-categories"
            >
              <CategorySelector
                v-model="additionalCategory"
                class="additional-categories__block"
                :make-disable-categories="false"
                placeholder="Дополнительная Категория"
              />
              <el-button
                style="margin: 5px;"
                @click="addAdditionalCategory"
              >
                Добавить
              </el-button>
            </div>
            <div
              v-for="(id, index) in form.additional_categories"
              :key="index"
              class="additional-categories__list"
            >
              <CategorySelector
                v-model="form.additional_categories[index]"
                :disabled="true"
                style="width: 100%"
              />
              <el-button
                icon="el-icon-delete"
                size="mini"
                type="danger"
                @click="toDeleteAdditionalCategory(id)"
              />
            </div>
          </el-form-item>

          <el-form-item label="Изображения">
            <div class="internal-form__upload-image">
              <el-upload
                ref="upload"
                action=""
                class="upload-demo"
                drag
                :file-list="fileList"
                :http-request="onFileSelect"
                :limit="8"
                :on-remove="handleRemoveImage"
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              </el-upload>
            </div>
            {{ $_ErrorMixin_getError('images') }}
          </el-form-item>

          <el-form-item label="Ссылка на видео">
            <el-input v-model="form.video" />
            {{ $_ErrorMixin_getError('video') }}
          </el-form-item>

          <el-form-item label="Город">
            <el-select
              v-model="form.city_id"
              filterable
              placeholder="Введите город"
              remote
              :remote-method="getCity"
              reserve-keyword
            >
              <el-option
                v-for="city in cityOptions"
                :key="city.id"
                :label="city.name_ua || city.name"
                :value="city.id"
              />
            </el-select>
            {{ $_ErrorMixin_getError('city_id') }}
          </el-form-item>

          <!--          <el-form-item label="Имя">-->
          <!--            <el-input v-model="form.contacts.username" />-->
          <!--            {{ $_ErrorMixin_getError('contacts.username') }}-->
          <!--          </el-form-item>-->

          <!--          <el-form-item label="Номер">-->
          <!--            <el-input v-model="form.contacts.phone" />-->
          <!--            {{ $_ErrorMixin_getError('contacts.phone') }}-->
          <!--          </el-form-item>-->

          <!--          <el-form-item label="Показывать контакты">-->
          <!--            <el-switch-->
          <!--              v-model="form.contacts.show"-->
          <!--              @change="!form.contacts.show"-->
          <!--            />-->
          <!--          </el-form-item>-->

          <el-form-item label="Дата начала и конца публикации">
            <el-date-picker
              v-model="dataRange"
              end-placeholder="Дата конца"
              :picker-options="pickerOptions"
              start-placeholder="Дата начала"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="newDataRange"
            />
            {{ $_ErrorMixin_getError('publish_starts_at') }}
          </el-form-item>
        </el-form>
        <el-button :disabled="oldUser" type="success" @click="onSubmit">
          Создать
        </el-button>

        <el-button :disabled="!oldUser" type="info" @click="changeWish">
          Сохранить
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ErrorMixin from '@/utils/ErrorMixin';
import {format} from 'date-fns';
import CategorySelector from '@/components/wishes/CategorySelector'

const defaultValues = {
  user_id:'',
  name: '',
  text: '',
  category_id: '',
  images: [],
  video: '',
  city_id: '',
  additional_categories: [],
  publish_starts_at:'',
  publish_ends_at:'',
  contacts: {
    // username: '',
    phone:'0673954830',
    show: false,
  },
};

function initialContactState() {
  return {
    // username: '',
    phone:'0673954830',
    show: false,
  }
}

export default {
  name: 'TenderForm',
  components:{CategorySelector},
  mixins: [ErrorMixin],
  data() {
    return {
      additionalCategory:'',
      internalUsers: [],
      tempData: [],
      tender: null,
      formTitle: null,
      userId:'',
      cityId:'',
      formData : null,
      selectedFile: null,
      isDrawerOpen: false,
      dataRange: [],
      category:[],
      wishToEdit: null,
      cityOptions: [],
      filterObject: {
        page: 1,
        search: '',
      },
      oldUser: false,
      fileList:[],
      pickerOptions: {
        shortcuts: [{
          text: 'Тендер на Неделю',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [end, start]);
          },
        }],
      },
      form: {...defaultValues},
    };
  },

  computed: {
    ...mapState('wishes/categories', [
      'categories',
    ]),

    ...mapState('cities', [
      'cities','onlyCities',
    ]),


  },
 async created() {
   this.internalUsers = await this.fetchInternalUserForDropdown()
    this.cityOptions = await this.preloadCities()
    await this.fetchCategories()
   if (this.$route.name === 'InternalWishesCreate') {
      this.isDrawerOpen = true;
    }
   if (this.$route.name === 'InternalWishesEdit') {
     this.tender = await this.fetchInternalWish(this.$route.params.id);
     this.isDrawerOpen = true;
     this.open(null, this.tender)
   }
  },
  methods: {
    ...mapActions('internalUsers', [
      'fetchInternalUserForDropdown',
    ]),
    ...mapActions('wishes/categories', ['fetchCategories']),
    ...mapActions('internalWishes', ['uploadInternalImage','createInternalWish','updateInternalWish','fetchInternalWish']),
    ...mapActions('cities', [
      'fetchCities','preloadCities',
    ]),
    open(_, wishToEdit) {
      this.isDrawerOpen = true;
      this.form.images = [];
      this.oldUser = !!wishToEdit
      this.$router.push({ name: 'InternalWishesCreate'})
      this.formTitle = wishToEdit ? 'Редактирование Тендера'  : 'Создание Тендера'
      if(!wishToEdit) return
      this.fillForm(wishToEdit)
    },
  async  getCity(query) {
      if (query !== '') {
       this.filterObject.search = query
       this.cityOptions = await this.fetchCities(this.filterObject)
      }
    },
    onClosed() {
      this.$router.push({ name: 'InternalWishes' })
      this.form = {...defaultValues}
      this.form.images = [];
      this.category = []
      this.fileList = []
      this.dataRange = []
      this.wishToEdit = null
      this.form.additional_categories = []
      this.resetContacts()

    },
   async onSubmit() {
      if(this.dataRange) {
        this.form.publish_starts_at = this.dataRange[0]
        this.form.publish_ends_at = this.dataRange[1]
      }
     this.form.category_id = this.category[this.category.length - 1]
      try {
        await this.createInternalWish(this.form)
        this.$message({
          message: 'Новый тендер был создан',
          type: 'success',
        });
        this.$_ErrorMixin_clearErrors()
        this.resetContacts()
        this.$refs.upload.clearFiles();
        this.$emit('update')
        this.form.images = [];
        this.isDrawerOpen = false
      } catch (e){
        this.$_ErrorMixin_handleError(e)
      }
    },

    async upLoad() {
      this.formData = await new FormData()
      await  this.formData.append('file', this.selectedFile, this.selectedFile.name)
      try {
       const img = await this.uploadInternalImage(this.formData)
        this.form.images.push(img)
        const imageUid = {uid : this.selectedFile.uid, id: img}
        this.tempData.push(imageUid)
      } catch (e){
        this.$_ErrorMixin_handleError(e)
      }
    },
    async onFileSelect(res) {
      this.selectedFile = await res.file
      await this.upLoad()
    },

    newDataRange(){
      const date = new Date()
      const defaultDate = format(date, 'yyyy-MM-dd')
      if(!this.dataRange.length){
        this.dataRange.push(defaultDate)
        this.dataRange.push(defaultDate)
      }
    },
   async changeWish(){
     this.form.user_id === this.wishToEdit.user.show_name ? this.form.user_id = this.userId : ''
     this.form.city_id === this.wishToEdit.city.name ? this.form.city_id = this.cityId : ''

     if(Array.isArray(this.category)) {
       this.form.category_id = this.category[this.category.length - 1]
     } else {
       this.form.category_id = this.category
     }
     if(this.dataRange) {
        this.form.publish_starts_at = this.dataRange[0]
        this.form.publish_ends_at = this.dataRange[1]
      }
      try {
        await this.updateInternalWish(this.form)
        this.$message({
          message: 'Новый тендер был изменен',
          type: 'success',
        });
        this.$_ErrorMixin_clearErrors()
        this.resetContacts()
        this.$refs.upload.clearFiles()
        this.$emit('update')
        this.form.images = [];
        this.isDrawerOpen = false
      } catch (e){
        this.form.city_id = this.wishToEdit.city.name
        this.$_ErrorMixin_handleError(e)
      }
    },
    resetContacts() {
      Object.assign(this.form.contacts, initialContactState())
    },

    handleRemoveImage(file) {
      if (this.wishToEdit) {
        this.wishToEdit.images.forEach(item => {
          if(file.name === item.name) {
            const indexOfImage = this.form.images.indexOf(item.id)
            this.form.images.splice(indexOfImage, 1)
          }
        })
        return
      }
        this.tempData.forEach(item => {
        if(file.uid === item.uid){
          const indexOfImage = this.form.images.indexOf(item.id)
          this.form.images.splice(indexOfImage, 1)
          this.tempData.splice(indexOfImage, 1)
        }
      })
    },

    fillForm(wishToEdit) {
      this.wishToEdit = wishToEdit
      this.wishToEdit.images.forEach(item => {
        this.fileList.push({name : item.name})
        this.form.images.push(item.id)
      })
      this.form.name = this.wishToEdit.name
      this.form.text = this.wishToEdit.description
      this.cityId = this.wishToEdit.city.id
      this.form.city_id = this.wishToEdit.city.name
      this.category = this.wishToEdit.category.id
      this.form.category_id = this.wishToEdit.category.name
      this.userId = this.wishToEdit.user.id
      this.form.user_id = this.wishToEdit.user.show_name
      this.form.wishId = this.wishToEdit.id
      if (this.wishToEdit.additional_categories.length >= 1) {
        this.wishToEdit.additional_categories.forEach( el => {
          this.form.additional_categories.push(el.public_id)
        })
        this.form.additional_categories = [...new Set(this.form.additional_categories)];
      }
      this.form.contacts.show = this.wishToEdit.show_contacts
      this.dataRange.push(this.wishToEdit.publish_starts_at)
      this.dataRange.push(this.wishToEdit.publish_ends_at)
      if(this.wishToEdit.contacts){
        this.form.contacts.phone = this.wishToEdit?.contacts.phone
        // this.form.contacts.username = this.wishToEdit?.contacts.username
      }
      this.form.video = this.wishToEdit?.video_link
      this.$router.push({ name: 'InternalWishesEdit', params: { id: wishToEdit.id } })

    },

    addAdditionalCategory(){
      // if(!this.additionalCategory) {
      //   this.$notify.error({
      //     title: 'Ошибка',
      //     message: 'Нужно выбрать Категорию',
      //   });
      //   return
      // }
      this.form.additional_categories.push(this.additionalCategory[this.additionalCategory.length - 1])
      this.additionalCategory = ''
    },

    toDeleteAdditionalCategory(id){
      this.form.additional_categories.splice(id, 1)
    },
  },
};
</script>

<style lang="sass">
.additional-categories__list
  display: flex
  padding: 10px
  gap: 10px

</style>
