<template>
  <div>
    <TopicForm v-if="$route.params.id" :id="$route.params.id" />
  </div>
</template>

<script>
import TopicForm from '../../components/topics/TopicForm.vue';

export default {
  components: {TopicForm},
}
</script>

