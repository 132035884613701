<template>
  <el-table-column
    align="left"
    label="Город и Область"
  >
    <template slot-scope="scope">
      <h4>{{ scope.row.city.name }}</h4>
      <div>{{ scope.row.city.region_name }}</div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: 'CityAndRegionColumn',
  props:{
    data:{
      type: Object,
      required:true,
    },
  },
}
</script>

<style scoped>

</style>
