<template>
  <div class="message" :class="message.is_mine ? 'message--my' : ''">
    <p class="message-created">
      {{ message.created_at }}
    </p>
    <p class="message-text">
      {{ message.text }}
    </p>
    <div v-if="message.media.length" class="message-images">
      <img 
        v-for="image of message.media"
        :key="image.id"
        class="message-image" 
        :src="image.path"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.message
  margin-bottom: 10px
  padding: 10px
  border-radius: 8px
  box-shadow: 0 1px 10px rgb(0 0 0 / 6%)
  background-color: #f1f1f1
  width: max-content
  max-width: 50%

.message--my
    background-color: #ccc
    text-align: right
    margin-left: auto

.message-created
  margin: 0
  text-align: right
  font-size: 12px

.message-text 
  margin: 10px 0 0

.message-images
  margin-top: 20px

.message-image
  width: 200px

</style>
