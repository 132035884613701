import api from '@/utils/api'

export default {
  state: () => ({
    complaints: null,
    wishesComplaintsReasons: [],
    offersComplaintsReasons: [],
    stateList: [
      {
        key: null,
        label: 'Все',
      },
      {
        key: 'NEW',
        label: 'Новые',
      },
      {
        key: 'APPROVED',
        label: 'Подтвержденные',
      },
      {
        key: 'DECLINED',
        label: 'Отклоненные',
      },
    ],
    orderList: [
      {
        key: 'ASC',
        label: 'Сначала старые',
      },
      {
        key: 'DESC',
        label: 'Сначала новые',
      },
    ],
  }),
  mutations: {
    setComplaints(state, payload) {
      state.complaints = payload
    },

    setWishesComplaintsReasons(state, payload) {
      state.wishesComplaintsReasons = payload
    },

    setOffersComplaintsReasons(state, payload) {
      state.offersComplaintsReasons = payload
    },
  },
  getters: {},
  actions: {
    async fetchComplaints({commit}, params) {
      const {data} = await api.http.get('admin/complaints', {params})
      commit('setComplaints', data)
    },

    async fetchComplaint(_, id) {
      const {data} = await api.http.get(`admin/complaints/${id}`)
      return data?.data
    },

    async approveComplaint(_, id) {
      await api.http.post(`admin/complaints/${id}/approve`)
    },

    async declineComplaint(_, {id, form}) {
      await api.http.post(`admin/complaints/${id}/decline`, {type: form.reason.type, reason: form.reason.text})
    },

    async getChatsBetweenUsersInComplaint(_, id) {
      const {data} = await api.http.get(`admin/complaints/${id}/chats`)
      return data?.data
    },

    async getDialogsInChatsBetweenTwoUsers(_, id) {
      const {data} = await api.http.get(`admin/complaints/chats/${id}/message`)
      return data
    },

    async wishesComplaintsReasons({commit}) {
      const {data} = await api.http.get('wishes/complaints/reasons')
      commit('setWishesComplaintsReasons', data?.data)
    },

    async offersComplaintsReasons({commit}) {
      const {data} = await api.http.get('offers/complaints/reasons')
      commit('setOffersComplaintsReasons', data?.data)
    },
  },
  namespaced: true,
}
