<template>
  <router-link class="user-info" :to="{name: 'ViewUser', params: {id: user.id}}">
    <UserAvatar :user="user" />
  </router-link>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar'

export default {
  name: 'UserInfo',
  components:{UserAvatar},
  props: {
    user: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"


.user-info
  display: flex
  align-items: center

.user-info__avatar
  margin-right: 15px
  margin-top: 4px
  margin-bottom: 4px
  width: 50px
  height: 50px
  border: 3px solid #FFFFFF
  box-shadow: 0 0 0 4px $color-text-accent-customer
  border-radius: 50%

  &.user-info__avatar--business
    border: 3px solid #FFFFFF
    box-shadow: 0 0 0 4px $color-text-accent-business


</style>
