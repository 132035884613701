<template>
  <div>
    <PageHeader page-name="Тематические Страницы">
      <template>
        <router-link :to="{name: 'CreateTopicPage'}">
          <el-button type="success">
            Добавить страницу
          </el-button>
        </router-link>
      </template>
    </PageHeader>
    <MainPanel>
      <el-table
        :data="list"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="#"
          type="index"
          width="50"
        />

        <el-table-column
          align="center"
          label="Состояние"
          prop="state"
          width="150"
        />

        <el-table-column
          align="center"
          label="Обновлено"
          prop="updated_at"
          width="150"
        />

        <el-table-column
          align="center"
          label="Ссылка"
          prop="url"
          width="150"
        >
          <template slot-scope="scope">
            <el-link :href="scope.row.url" type="primary">link</el-link>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="Название"
          prop="title"
        />

        <el-table-column
          align="center"
          fixed="right"
          label="Изменить"
          width="120"
        >
          <template slot-scope="scope">
            <router-link :to="{name: 'EditTopicPage', params: {id: scope.row.id}}">
              <el-button
                circle
                icon="el-icon-edit"
                size="small"
                type="primary"
              />
            </router-link>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          fixed="right"
          label="Удалить"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              circle
              icon="el-icon-delete"
              size="small"
              type="danger"
              @click="deleteModal(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </MainPanel>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import MainPanel from '../../components/common/MainPanel.vue';
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'TopicsPage',
  components: {PageHeader, MainPanel},
  computed: {
    ...mapState('topics', {
      list: state => state.topics,
    }),
  },
  created() {
    this.getAllTopics()
  },
  methods: {
    ...mapActions('topics', ['getAllTopics', 'deleteTopic']),

    async deleteModal(id)
    {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Вы действительно хотите удалить эту страницу?', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {
        console.error(e)
      }

      if (!confirmation) {
        return
      }
      try {
        await this.deleteTopic(id)
        await this.$message.success('Страница успешно  удалена!');
      } catch (e) {
        await this.$message.error('Страницу НЕ удалось удалить!)');
      }
    },
  },
}
</script>
