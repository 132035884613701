<template>
  <MainPanel v-if="trashWish" v-loading="loading">
    <UserAvatar
      :is-big="true"
      style="margin-bottom: 20px"
      :user="trashWish.user"
    />
    <table class="key-values-table">
      <tr>
        <td>id</td>
        <td>{{ trashWish.id }}</td>
      </tr>
      <tr>
        <td>Название</td>
        <td>{{ trashWish.name }}</td>
      </tr>
      <tr>
        <td>Категория</td>
        <td v-if="trashWish.category">
          {{ trashWish.category.name }}
        </td>
        <td v-else>{{ 'Категория не выбранна' }}</td>
      </tr>
      <tr>
        <td>Дата создания</td>
        <td>{{ trashWish.created_at }}</td>
      </tr>
      <tr>
        <td>Дата удаления</td>
        <td>{{ trashWish.deleted_at }}</td>
      </tr>
      <tr>
        <td>Город</td>
        <td>{{ trashWish.city.name }}</td>
      </tr>
      <tr>
        <td>Область</td>
        <td>{{ trashWish.city.region_name }}</td>
      </tr>
      <tr>
        <td>Описание</td>
        <td>{{ trashWish.description }}</td>
      </tr>
      <tr>
        <td>Количество жалоб</td>
        <td>{{ trashWish.complaints_count }}</td>
      </tr>
      <tr>
        <td>Состояние</td>
        <td>
          {{ trashWish.condition }}
        </td>
      </tr>
    </table>
  </MainPanel>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import {mapActions} from 'vuex';

export default {
  name: 'ViewPage',
  components: {MainPanel, UserAvatar},

  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
      trashId: null,
      trashWish:null,
    }
  },

  async created() {
    this.trashId = this.$route.params.id
    this.trashWish = await this.fetchWishTrash(this.trashId)
  },

  methods:{
    ...mapActions('trash', [
      'fetchWishTrash',
    ]),
  },
}
</script>

<style scoped>

</style>
