<template>
  <el-table
    :data="list && list.data"
    :default-sort="{prop: 'name', order: 'ascending'}"
    stripe
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column
      align="center"
      label="Назва міста"
      prop="name_ua"
      sortable="custom"
    />
    <el-table-column
      align="center"
      label="Назва області"
      prop="region.name_ua"
    />
    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <router-link class="name-wrapper" :to="{name: 'ViewCityPage', params: {city_id: scope.row.id}}">
          <el-button
            size="small"
            type="info"
          >
            <i class="far fa-eye" />
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'List',
  computed: {
    ...mapState('cities', {
      list: 'cities',
    }),
  },
  methods:{
    onSortChange(val) {
      if (val.prop === 'name') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'name_asc' : 'name_desc',
        })
      }
      if (val.prop === 'name_ua') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'name_ua_asc' : 'name_ua_desc',
        })
      }
    },
  },

}
</script>

<style scoped>

</style>
