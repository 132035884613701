<template>
  <el-table
    :data="data"
    stripe
    style="width: 100%"
  >
    <el-table-column
      align="left"
      label="Имя"
      prop="name"
    />
    <el-table-column
      align="left"
      label="Категория"
    >
      <template slot-scope="scope">
        <div v-if="scope.row.category"> {{ scope.row.category.name }}</div>
        <div v-else style="font-weight: bold">{{ 'Категория не выбранна' }}</div>
      </template>
    </el-table-column>

    <CityAndRegionColumn :data="data" />

    <el-table-column
      align="left"
      label="Дата создания"
      prop="created_at"
    />
    <el-table-column
      align="left"
      label="Дата удаления"
      prop="deleted_at"
    />

    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <router-link class="name-wrapper" :to="{name: 'ViewOfferTrash', params: {id: scope.row.id}}">
          <el-button
            size="small"
            type="info"
          >
            <i class="far fa-eye" />
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import CityAndRegionColumn from '@/components/column-components/CityAndRegionColumn';

export default {
  name: 'List',
  components: {CityAndRegionColumn},
  props:{
    data:{
      type: [Object, Array],
      required:true,
    },
  },
}
</script>

<style scoped>

</style>
