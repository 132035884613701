<template>
  <div>
    <div v-if="form" class="internal-form">
      <el-form
        ref="form"
        label-position="top"
        label-width="220px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="Заголовок мероприятия" prop="title">
          <el-input id="name" v-model="form.title" />
          {{ $_ErrorMixin_getError('title') }}
        </el-form-item>

        <el-form-item label="Описание мероприятия" prop="description">
          <el-input id="descr" v-model="form.description" type="textarea" />
          {{ $_ErrorMixin_getError('description') }}
        </el-form-item>


        <el-form-item v-if="eventTypes" label="Тип мероприятия" prop="event_type_id">
          <el-select id="type_event" v-model="form.event_type_id" placeholder="Тип мероприятия">
            <el-option
              v-for="type in eventTypes"
              :key="type.id"
              :label="type.title"
              :value="type.id"
              @change="eventTypeChange"
            />
          </el-select>
          {{ $_ErrorMixin_getError('event_type_id') }}
        </el-form-item>

        <div class="price_date_time_block">
          <div class="price_date_time_block__item">
            <el-form-item label="Цена">
              <div class="price">
                <el-col :span="12" style="display: flex; align-items: center;">
                  <el-input id="price" v-model="form.price" style="flex: 1;" />
                  <span class="grn">грн.</span>
                </el-col>
              </div>
              {{ $_ErrorMixin_getError('price') }}
            </el-form-item>
          </div>

          <div class="price_date_time_block__item">
            <el-form-item label="Дата проведения мероприятия" prop="date">
              <el-col :span="12">
                <el-date-picker
                  id="date_event"
                  v-model="form.date"
                  :picker-options="pickerOptions"
                  placeholder="Дата"
                  type="date"
                  value-format="yyyy-MM-dd"
                />
                <br>
                {{ $_ErrorMixin_getError('date') }}
              </el-col>
            </el-form-item>
          </div>

          <div class="price_date_time_block__item">
            <el-form-item label="Время проведения" prop="time">
              <el-col :span="12">
                <el-input id="time_event" v-model="form.time" type="time" />
                {{ $_ErrorMixin_getError('time') }}
              </el-col>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="Город" prop="city_id">
          <el-select
            v-if="cityOptions"
            id="town"
            v-model="selectedCityId"
            filterable
            placeholder="Введите город"
            remote
            :remote-method="getCity"
            reserve-keyword
            @change="selectedCityChange"
          >
            <el-option
              v-for="city in cityOptions"
              :key="city.id"
              :label="city.name"
              :value="city.id"
            />
          </el-select>
          {{ $_ErrorMixin_getError('city_id') }}
        </el-form-item>

        <el-form-item label="Название заведения" prop="place_name">
          <el-input id="name_estab" v-model="form.place_name" />
          {{ $_ErrorMixin_getError('place_name') }}
        </el-form-item>

        <el-form-item label="Адрес" prop="place_address">
          <el-input id="addr_estab" v-model="form.place_address" />
          {{ $_ErrorMixin_getError('place_address') }}
        </el-form-item>

        <el-form-item label="Источник" prop="source_url">
          <el-input id="link_estab" v-model="form.source_url" />
          {{ $_ErrorMixin_getError('source_url') }}
        </el-form-item>

        <el-form-item label="Контакты" prop="contacts">
          <el-input id="contact_estab" v-model="form.contacts" type="textarea" />
          {{ $_ErrorMixin_getError('contacts') }}
        </el-form-item>

        <div class="fotos_videos_block">
          <div class="fotos_videos_block__item">
            <div
              v-for="(photo, index) in photos"
              :key="index"
            >
              <el-form-item
                label="Фото URL"
              >
                <el-input :id="`photo_${index}`" v-model="photos[index]" />
              </el-form-item>
              {{ $_ErrorMixin_getError(`image_links.${index}`) }}
            </div>
          </div>

          <div class="fotos_videos_block__item">
            <div
              v-for="(video, index) in videos"
              :key="index"
            >
              <el-form-item
                label="Видео URL"
              >
                <el-input :id="`video_${index}`" v-model="videos[index]" />
              </el-form-item>
              {{ $_ErrorMixin_getError(`video_links.${index}`) }}
            </div>
          </div>
        </div>
      </el-form>
      <el-button type="success" @click="save">
        <span v-if="id">Изменить</span>
        <span v-else>Создать</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ErrorMixin from '@/utils/ErrorMixin';

const defaultValues = {
  title: '',
  description: '',
  event_type_id: null,
  date: null,
  time: null,
  price:'',
  currency: 'UAH',
  city_id: null,
  place_name: '',
  place_address: '',
  image_links: [],
  video_links: [],
  source_url: '',
  contacts: '',
};


export default {
  name: 'EventForm',
  mixins: [ErrorMixin],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formTitle: null,
      formData: null,
      form: {},
      cities: null,
      cityOptions: [],
      eventType: null,
      selectedCityId: null,
      filterObject: {
        page: 1,
        search: '',
      },
      photos: new Array(8).fill(null),
      videos: new Array(8).fill(null),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        },
      },
        rules: {
          title: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 255, message: 'Заголовок должен быть от 3 до 255 символов', trigger: 'blur' },
          ],
          description: [{min: 3, max: 8000, message: 'Описание должно быть от 3 до 8000 символов', trigger: 'blur'}],
          event_type_id: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
          date: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
          city_id: [{required: true, message: 'Обязательное поле', trigger: 'blur' }],
          place_name: [
            {required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 255, message: 'Название заведения должно быть от 3 до 255 символов', trigger: 'blur' },
          ],
          place_address: [
            { min: 3, max: 255, message: 'Адрес должен быть от 3 до 255 символов', trigger: 'blur' },
          ],
          source_url: [
            {required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 255, message: 'Источник должен быть от 3 до 255 символов', trigger: 'blur' },
          ],
          contacts: [
            { min: 3, max: 255, message: 'Источник должен быть от 3 до 255 символов', trigger: 'blur' },
          ],
      },
    }
  },
  computed: {
    ...mapState('events', ['eventTypes']),
  },
  async created() {
    await this.getAllEventTypes();
    this.cityOptions = await this.preloadCities()

    if (this.id) {
      await this.getEventForEdit()

    } else {
      this.form = {...defaultValues}
    }
  },
  methods:{
    ...mapActions('events', [
      'getAllEventTypes', 'createEvent', 'getEventById', 'editEvent',
    ]),
    ...mapActions('cities', [
      'fetchCities','preloadCities',
    ]),

    eventTypeChange() {
        this.form.event_type_id = this.eventType.id
    },

    selectedCityChange() {
      this.form.city_id = this.selectedCityId
    },

    async getEventForEdit () {
      const event = await this.getEventById(this.id);
      this.form = {
        id: event.id,
        title: event.title,
        description: event.description,
        event_type_id: event.event_type.id,
        date: event.date,
        time: event.time,
        price: event.price,
        currency: event.currency,
        city_id: event.city.id,
        place_name: event.place_name,
        place_address: event.place_address,
        image_links: event.image_links,
        video_links: event.video_links,
        source_url: event.source_url,
        contacts: event.contacts,
      }

      this.selectedCityId = event.city.id
      this.eventType = {...event.event_type}

      this.photos = this.photos.map((photo, index) => {
        return event.image_links[index] || null;
      });

      this.videos = this.videos.map((photo, index) => {
        return event.video_links[index] || null;
      });
    },

    saveVideoLinks () {
      this.form.video_links = this.videos.filter(item => item);
    },
    savePhotoLinks () {
      this.form.image_links = this.photos.filter(item => item);
    },
    async  getCity(query) {
      if (query !== '') {
        this.filterObject.search = query
        this.cityOptions = await this.fetchCities(this.filterObject)
      }
    },
    async save(){
      this.saveVideoLinks()
      this.savePhotoLinks()
      this.$refs['form'].validate(  async (valid) => {

       if (valid) {
           try {
             this.id ? await this.editEvent(this.form) : await this.createEvent(this.form)
             await this.$router.push({name: 'AllEventsPage'})
           } catch (e) {
             this.$_ErrorMixin_handleError(e)
           }
        } else {
          return false;
        }
      });
      },


  },
}
</script>

<style>
.fotos_videos_block {
  display: flex;
  gap: 20px;
  width: 100%;
}

.fotos_videos_block__item{
  width: 50%;
}

.price_date_time_block {
  display: flex;
  gap: 20px;
  width: 100%;
}

.price_date_time_block__item{
  width: 33%;
}

.price {
  display: flex;
  align-items: center;
}

.grn {
  margin-left: 5px
}
</style>
