<template>
  <div>
    <PageHeader page-name="Внутренние пользователи">
      <template>
        <el-button type="success" @click="toEdit">
          Добавить
        </el-button>
      </template>
    </PageHeader>
    <InternalUserForm ref="userForm" @update="getInternalUsers" />
    <MainPanel v-if="internalUsers">
      <div v-loading="loading">
        <el-table :data="internalUsers.data" style="width: 100%">
          <el-table-column align="center" label="Никнейм" prop="username" />
          <el-table-column align="center" label="Имя" prop="first_name" />
          <el-table-column align="center" label="Фамилия" prop="last_name" />
          <el-table-column align="center" label="Дата регистрации" prop="registration_date" />
          <el-table-column align="center" label="Тип аккаунта" prop="account_type">
            <template slot-scope="scope">
              <el-tag
                class="type-tag"
                size="small"
                :type="scope.row.account_type.toLowerCase() === 'business' ? 'success' : 'warning'"
              >
                {{ scope.row.account_type }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Тип пакета" prop="package_type">
            <template slot-scope="scope">
              <el-tag
                class="type-tag"
                size="small"
                :type="scope.row.package_type.toLowerCase() === 'business_base' ? 'success' : 'warning'"
              >
                {{ scope.row.package_type }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Пользователь /Редактировать" prop="public_id">
            <template slot-scope="scope">
              <el-button
                circle
                icon="el-icon-user-solid"
                type="warning"
                @click="onView(scope.row.id)"
              />
              <el-button
                circle
                icon="el-icon-edit"
                prop="public_id"
                type="primary"
                @click="toEdit(scope.row.id)"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="Перейти на сайт" prop="login_link">
            <template slot-scope="scope">
              <a :href="scope.row.login_link" target="_blank">
                <el-button circle icon="el-icon-right" type="success" />
              </a>
            </template>
          </el-table-column>
          <el-table-column label="Изображение">
            <template slot-scope="scope">
              <el-avatar
                v-if="scope.row.avatar"
                class="el-avatar"
                fit="contain"
                shape="square"
                :size="100"
                :src="scope.row.avatar.path"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainPanel from '@/components/common/MainPanel';
import InternalUserForm from '@/components/internal-user-form/InternalUserForm';
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'ListPage',
  components: { MainPanel, InternalUserForm, PageHeader },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
    };
  },
  computed: {
    ...mapState('internalUsers', ['internalUsers']),
    meta() {
      return this.internalUsers?.meta;
    },
  },
  async created() {
    await this.getInternalUsers(this.filterObject);
  },
  methods: {
    ...mapActions('internalUsers', ['fetchInternalUsers']),
    changePage(page) {
      this.filterObject.page = page;
      this.getInternalUsers();
    },
    onView(id) {
      this.$router.push({ name: 'InternalUser', params: { id } });
    },

    async getInternalUsers() {
      this.loading = true;
      await this.fetchInternalUsers(this.filterObject);
      this.loading = false;
    },

    toEdit(userId) {
      if (!userId) {
        this.$refs.userForm.openDrawer();
      } else {
        const selectedUser = this.internalUsers.data.find(user => user.id === userId);
        this.$refs.userForm.openDrawer(selectedUser);
      }
    },
  },
};
</script>
