<template>
  <img
    v-if="tender.first_image"
    height="58"
    :src="tender.first_image.conversions.feed"
    style="object-fit: cover"
    width="100"
  >
</template>

<script>
export default {
  name: 'TenderImageColumn',
  props: {
    tender: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
