<template>
  <el-form
    ref="form"
    class="filter-form"
    label-position="top"
    :model="filters"
  >
    <el-form-item label="Поиск">
      <el-input
        v-model="filters.search"
        v-debounce="doFilter"
        placeholder="Поиск"
        prefix-icon="el-icon-search"
      />
    </el-form-item>
    <el-button
      v-if="isFiltersChanged"
      type="gray"
      @click="clearFilters"
    >
      Сбросить фильтры
    </el-button>
  </el-form>
</template>

<script>

const defaultFilterValues = {
  search: '',
}
export default {
  name: 'FilterWishesTrash',
  data() {
    return {
      filters: {...defaultFilterValues},
      originFilters: {...defaultFilterValues},
    }
  },



  computed: {

    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultFilterValues}
      this.$emit('filter', this.filters)
    },
  },
}


</script>

<style scoped>

</style>
