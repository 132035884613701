import {Message} from 'element-ui'

export function dropzoneErrorHandler(e) {
  let message
  const response = JSON.parse(e.message)

  if (response.errors) {
    const errorsArray = Object.values(response?.errors)
    message = `${response.message ? response.message : ''}\n${errorsArray.map(i => i.join('\n')).join('\n')}`
  } else {
    message = response.message
  }

  Message.error(message)
}
