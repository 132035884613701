<template>
  <el-form
    ref="form"
    class="filter-form"
    label-position="top"
    :model="filters"
  >
    <el-form-item label="Поиск">
      <el-input
        v-model="filters.search"
        v-debounce="doFilter"
        placeholder="Поиск"
        prefix-icon="el-icon-search"
      />
    </el-form-item>

    <el-form-item label="По Области">
      <el-select
        v-model="filters.region_id"
        class="multiselect"
        @change="doFilter"
      >
        <el-option
          v-for="state in [{id: null, name: 'Любой'}, ...regions]"
          :key="state.id"
          :label="state.name"
          :value="state.id"
        />
      </el-select>
    </el-form-item>

    <el-button
      v-if="isFiltersChanged"
      type="gray"
      @click="clearFilters"
    >
      Сбросить фильтры
    </el-button>
  </el-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex';


const defaultFilterValues = {
  search: '',
  region_id: null,
}

export default {
  name: 'FilterGeo',
  data() {
    return {
      filters: {...defaultFilterValues},
      originFilters: {...defaultFilterValues},
    }
  },

  computed: {
    ...mapGetters('regions', [
      'regionsTransformed',
    ]),
    ...mapState('regions', [
      'regions',
    ]),

    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultFilterValues}
      this.$emit('filter', this.filters)
    },
  },
}
</script>

<style scoped>

</style>
