<template>
  <div class="search-box">
    <h2>Топ товари</h2>
    <el-select
      v-model="selectedProducts"
      filterable
      multiple
      placeholder="Виберіть товари"
    >
      <el-option
        v-for="product in products"
        :key="product.id"
        :label="product.name"
        :value="product.id"
      />
    </el-select>
    <el-button type="primary" @click="saveProducts">Зберегти</el-button>

    <h2>Категорії</h2>
    <el-select
      v-model="selectedCategories"
      filterable
      multiple
      placeholder="Виберіть категорії"
    >
      <el-option
        v-for="category in categories"
        :key="category.id"
        :label="category.name"
        :value="category.id"
      />
    </el-select>
    <el-button type="primary" @click="saveCategories">Зберегти</el-button>
  </div>
</template>

<script>
import api from '@/utils/api';

export default {
  name: 'SearchManagement',
  data() {
    return {
      products: [],
      selectedProducts: [],
      categories: [],
      selectedCategories: [],
    };
  },
  created() {
    this.fetchProducts();
    this.fetchCategories();
    this.loadSelectedItems();
  },
  methods: {
    fetchProducts() {
      api.http.get('/admin/products')
        .then(response => {
          this.products = response.data.data;
        })
        .catch(error => {
          console.error('Помилка під час отримання товарів:', error);
        });
    },
    fetchCategories() {
      api.http.get('/admin/categories')
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(error => {
          console.error('Помилка під час отримання категорій:', error);
        });
    },
    loadSelectedItems() {
      api.http.get('/admin/search/products')
        .then(response => {
          const selectedProductsData = response.data.data;
          this.selectedProducts = selectedProductsData.map(product => product.id);
        })
        .catch(error => {
          console.error('Помилка під час завантаження вибраних товарів:', error);
        });

      api.http.get('/admin/search/categories')
        .then(response => {
          const selectedCategoriesData = response.data.data;
          this.selectedCategories = selectedCategoriesData.map(category => category.id);
        })
        .catch(error => {
          console.error('Помилка під час завантаження вибраних категорій:', error);
        });
    },
    saveProducts() {
      api.http.post('/admin/search/products', {
        products: this.selectedProducts,
      })
      .then(() => {
        this.$message.success('Топ товари збережено успішно!');
      })
      .catch(error => {
        console.error('Помилка під час збереження товарів:', error);
      });
    },
    saveCategories() {
      api.http.post('/admin/search/categories', {
        categories: this.selectedCategories,
      })
      .then(() => {
        this.$message.success('Категорії збережено успішно!');
      })
      .catch(error => {
        console.error('Помилка під час збереження категорій:', error);
      });
    },
  },
};
</script>

<style scoped>
.search-box {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.el-form-item {
  display: flex;
  flex-direction: column;
}

.el-button {
  width: fit-content;
}
</style>
