import api from '@/utils/api'

export default {
  state: () => ({
    currencies: [],
    wishConditions: [],
    offerConditions: [],
    productConditions: [],
  }),
  mutations: {
    setCurrencies(state, payload) {
      state.currencies = payload
    },
    setWishConditions(state, payload) {
      state.wishConditions = payload
    },
    setOfferConditions(state, payload) {
      state.offerConditions = payload
    },
    setProductConditions(state, payload) {
      state.productConditions = payload
    },
  },
  getters: {
    getWishConditionByKey(state) {
      return key => {
        return state.wishConditions?.find(x => x.value === key)?.title
      }
    },
    getOfferConditionByKey(state) {
      return key => {
        return state.offerConditions?.find(x => x.value === key)?.title
      }
    },
    getProductConditionByKey(state) {
      return key => {
        return state.productConditions?.find(x => x.value === key)?.title
      }
    },
    getCurrencyByKey(state) {
      return key => {
        return state.currencies?.find(x => x.id === key)?.title
      }
    },
    getStatusTagType() {
      return key => {
        switch (key) {
          case 'NEW':
            return 'danger'
          case 'DISCARDED':
            return 'danger'
          case 'CHECKED':
            return 'success'
          case 'HIDDEN':
            return 'info'
          case 'CHECK_REQUESTED':
            return 'warning'
          case 'DRAFT':
            return 'danger'
          case 'ON_MODERATION':
            return 'warning'
          case 'PUBLISHED':
            return 'success'
          case 'ARCHIVED':
            return 'info'

        }
      }
    },
  },
  actions: {
    async fetchCurrencies({commit}) {
      const res = await api.http.get('value-objects/currencies')
      commit('setCurrencies', res?.data)
    },

    async fetchWishConditions({commit}) {
      const res = await api.http.get('value-objects/wish-conditions')
      commit('setWishConditions', res?.data)
    },

    async fetchOfferConditions({commit}) {
      const res = await api.http.get('value-objects/offer-conditions')
      commit('setOfferConditions', res?.data)
    },

    async fetchProductConditions({commit}) {
      const res = await api.http.get('/value-objects/product-conditions')

      commit('setProductConditions', res?.data)
    },

  },
  namespaced: true,
}
