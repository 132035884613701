import api from '@/utils/api'

export default {
  actions: {
    async getAdminActivity(_, actorId) {
      try {
        const { data } = await api.http.get(`admin/admins/${actorId}/log`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getUserActivity(_, actorId) {
      try {
        const { data } = await api.http.get(`admin/users/${actorId}/log`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getActivityOverOffer(_, subjectId) {
      try {
        const { data } = await api.http.get(`admin/offers/${subjectId}/log`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getActivityOverComplaint(_, complaintId) {
      try {
        const { data } = await api.http.get(`admin/complaints/${complaintId}/log`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getActivityOverTicket(_, ticketId) {
      try {
        const { data } = await api.http.get(`admin/helpcenter/tickets/${ticketId}/log`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getActivityOverUser(_, userId) {
      try {
        const { data } = await api.http.get(`admin/users/${userId}/log/as-subject`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getActivityOverWish(_, wishId) {
      try {
        const { data } = await api.http.get(`admin/wishes/${wishId}/log`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  namespaced: true,
}
