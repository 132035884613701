<template>
  <div>
    <PageHeader page-name="Типы мероприятий">
      <template>
        <el-button type="success" @click="openCreateForm">
          Добавить
        </el-button>
      </template>
    </PageHeader>
    <EventTypeForm
      ref="eventTypeForm"
    />
    <EventTypeView
      ref="eventTypeView"
    />
    <MainPanel>
      <el-table
        :data="list"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="#"
          type="index"
          width="50"
        />

        <el-table-column
          align="center"
          label="Название Ua"
          prop="title"
        />
        <el-table-column
          align="center"
          label="Название Ru"
          prop="title_ru"
        />

        <el-table-column
          align="center"
          label="SEO-title Ua"
          prop="seo_title"
        />

        <el-table-column
          align="center"
          label="Seo-title Ru"
          prop="seo_title_ru"
        />

        <el-table-column
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              circle
              icon="el-icon-edit"
              size="small"
              type="primary"
              @click="editEventType(scope.row.id)"
            />

            <el-button
              circle
              icon="el-icon-view"
              size="small"
              type="info"
              @click="onView(scope.row.id)"
            />
            <el-button
              circle
              icon="el-icon-delete"
              size="small"
              type="danger"
              @click="deleteModal(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </MainPanel>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import MainPanel from '../../components/common/MainPanel.vue';
import PageHeader from '@/components/common/PageHeader';
import EventTypeForm from '../../components/eventTypes/EventTypeForm.vue';
import EventTypeView from '../../components/eventTypes/EventTypeView.vue';

export default {
  name: 'EventTypesPage',
  components: {EventTypeView, EventTypeForm, PageHeader, MainPanel},
  computed: {
    ...mapState('events', {
      list: state => state.eventTypes,
    }),
  },
  created() {
    this.getAllEventTypes()
  },
  methods: {
    ...mapActions('events', ['getAllEventTypes', 'deleteEventType']),

    onView (id) {
      const selectedEventType = this.getEventTypeById(id)
      this.$refs.eventTypeView.openDrawer(selectedEventType)
    },
    openCreateForm() {
      this.$refs.eventTypeForm.openDrawer()
    },
    editEventType(id) {
      const selectedEventType = this.getEventTypeById(id)
      this.$refs.eventTypeForm.openDrawer(selectedEventType)
    },
    getEventTypeById(id) {
      return this.list.find((eventType) => eventType.id === id)
    },
    async deleteModal(id)
    {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Вы действительно хотите удалить этот тип?', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {
        console.error(e)
      }

      if (!confirmation) {
        return
      }
      try {
        await this.deleteEventType(id)
        await this.$message.success('Тип мероприятия успешно  удален!');
      } catch (e) {
        await this.$message.error('Тип мероприятия НЕ удалось удалить!)');
      }
      return
    },
  },
}
</script>
