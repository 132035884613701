import api from '../utils/api';

export default {
  state: () => ({
    topics: null,
  }),
  mutations: {
    setTopics(state, topics) {
      state.topics = topics;
    },
  },
  getters: {},
  actions: {
    async getAllTopics({commit}) {
      const {data} = await api.http.get('admin/topics')
      commit('setTopics', data.data)
    },
    async getTopicById(_, id) {
      const {data} = await api.http.get(`/admin/topics/${id}`)

      return data
    },
    async createTopic({dispatch}, form) {
      const data = await api.http.post('/admin/topics', {...form})
      dispatch('getAllTopics')
      return data
    },
    async uploadTopicImage(_, file) {
      const res = await api.http.post('/admin/topics/upload-image', file)
      return res.data.data
    },
    async editTopic({dispatch}, form) {
      const {data} = await api.http.put(`/admin/topics/${form.id}`, {...form})
      dispatch('getAllTopics')
      return data
    },

    async deleteTopic({dispatch}, id) {
      const {data} = await api.http.delete(`/admin/topics/${id}`)
      dispatch('getAllTopics')
      return data
    },
  },
  namespaced: true,
}
