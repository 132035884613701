<template>
  <div>
    <EventForm v-if="$route.params.id" :id="$route.params.id" />
  </div>
</template>

<script>
import EventForm from '../../components/events/EventForm.vue';

export default {
  components: {EventForm},
}
</script>

