<template>
  <div>
    <PageHeader page-name="Внутренние тендеры">
      <template v-if="internalUsers">
        <el-select
          v-model="selectedStatus"
          placeholder="Статус"
        >
          <el-option
            v-for="item in status"
            :key="item.state"
            :label="item.state"
            :value="item.state"
          />
        </el-select>
        <el-select
          v-model="selectedName"
          placeholder="Имя"
        >
          <el-option
            v-for="item in internalUsers.data"
            :key="item.id"
            :label="item.username"
            :value="item.id"
          />
        </el-select>
        <el-button
          icon="el-icon-search"
          type="primary"
          @click="filter"
        >
          Поиск
        </el-button>
        <el-button
          v-if="selectedName || selectedStatus"
          icon="el-icon-delete"
          type="primary"
          @click="clear"
        >
          Сброс
        </el-button>
        <el-button
          icon="el-icon-plus"
          type="success"
          @click="openCreateForm"
        >
          Создать
        </el-button>
      </template>
    </PageHeader>
    <MainPanel v-if="internalWishes">
      <el-table
        :data="internalWishes.data"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="Название"
          prop="name"
        />
        <el-table-column label="Изображение">
          <template slot-scope="scope">
            <el-avatar
              v-if="scope.row.images[0]"
              class="el-avatar"
              fit="contain"
              shape="square"
              :size="100"
              :src="scope.row.images[0].path"
            />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Категория"
          prop="category.name"
        />
        <el-table-column
          align="center"
          label="Статус"
          prop="state"
        />
        <el-table-column
          align="center"
          label="Пользователь-автор"
          prop="user.short_name"
        />
        <el-table-column
          align="center"
          label="Просмотр"
          prop="public_id"
        >
          <template slot-scope="scope">
            <el-button
              circle
              icon="el-icon-user-solid"
              type="warning"
              @click="openTenderView(scope.row.id)"
            />
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="Редактировать"
          prop="public_id"
        >
          <template slot-scope="scope">
            <el-button
              circle
              icon="el-icon-edit"
              prop="public_id"
              type="primary"
              @click="toEdit(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>

      <TenderForm ref="tenderForm" @update="getInternalWishes" />
      <ViewDrawer ref="tenderViewDrawer" @update="getInternalWishes" />

      <el-pagination
        v-if="internalWishes.meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="internalWishes.meta.per_page"
        :total="internalWishes.meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import PageHeader from '@/components/common/PageHeader';
import MainPanel from '@/components/common/MainPanel';
import TenderForm from '@/components/internal/tenders/TenderForm';
import ViewDrawer from '@/components/internal/tenders/ViewDrawer.vue';

export default {
  name: 'ListPage',
  components: {ViewDrawer, TenderForm, PageHeader, MainPanel},

  data() {
    return {
      internalWishes: null,
      status: null,
      selectedStatus: null,
      names: null,
      selectedName:null,
      loading: false,
      filterObject: {
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('internalUsers',['internalUsers']),
  },
  async created() {
    await this.fetchInternalUsers()
    await this.getInternalWishes()
    this.findStates()
  },
  methods: {
    ...mapActions('internalWishes', [
      'fetchInternalWishes',
    ]),
    ...mapActions('internalUsers', [
      'fetchInternalUsers',
    ]),
    changePage(page) {
      this.filterObject.page = page
      this.getInternalWishes()
    },
    async getInternalWishes() {
      this.loading = true
      this.internalWishes = await this.fetchInternalWishes({user_id: this.selectedName, state: this.selectedStatus, page: this.filterObject.page})
      this.loading = false
    },

    findStates() {
      let states = []
      this.internalWishes.data.forEach((el) => states.push(el.state))
      this.status = this.arrTransform(states)
    },

    async filter() {
      this.internalWishes = await this.fetchInternalWishes({user_id: this.selectedName, state: this.selectedStatus, page: 1})
    },

    clear() {
        this.selectedStatus = null;
        this.selectedName = null;
        this.getInternalWishes()
    },

    arrTransform(arr) {
      const uniqElements = new Set([...arr])
      const uniqArr = Array.from(uniqElements)
      return uniqArr.map((state) => ({state}))
    },
    openCreateForm(tenderId) {
      this.$refs.tenderForm.open(tenderId);
    },
    openTenderView(tenderId) {
      this.$refs.tenderViewDrawer.open(tenderId);
    },
    toEdit(wishId){
      if(!wishId) {
        this.$refs.tenderForm.open()
      } else {
        const selectedWish = this.internalWishes.data.find((wish) => wish.id === wishId)
        this.$refs.tenderForm.open(null, selectedWish)
      }

      },

    },
}
</script>
