<template>
  <div>
    <div class="title-bar">
      <h2>Список действий</h2>
      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: listLink}">
          <el-button type="gray">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>
      </div>
    </div>

    <MainPanel v-loading="loading">
      <el-table :data="data" stripe>
        <el-table-column label="Кто проводил действие" width="300">
          <template slot-scope="scope">
            <div class="contragent-label">
              {{ getContragentType(scope.row.actor_type) }}
            </div>

            <div v-if="scope.row.actor_type === 'admin'">
              {{ scope.row.actor.email }}
            </div>
            <UserInfo v-if="scope.row.actor_type === 'user'" :user="scope.row.actor" />
          </template>
        </el-table-column>

        <el-table-column
          :label="`Над ${data[0] && data[0].subject_type === 'user' ? 'кем' : 'чем'} проводилось действие`"
        >
          <template slot-scope="scope">
            <div class="contragent-label">
              {{ getContragentType(scope.row.subject_type) }}
            </div>

            <div v-if="scope.row.subject_type === 'admin'">
              {{ scope.row.subject_type.email }}
            </div>

            <UserInfo v-if="scope.row.subject_type === 'user'" :user="scope.row.subject" />
            <WishInfo v-if="scope.row.subject_type === 'wish'" :wish="scope.row.subject" />
            <OfferInfo v-if="scope.row.subject_type === 'offer'" :offer="scope.row.subject" />
            <ProductInfo v-if="scope.row.subject_type === 'product'" :product="scope.row.subject" />

            <router-link
              v-if="scope.row.subject_type === 'complaint'"
              :to="{name: 'ViewComplaint', params: {id: scope.row.subject.id}}"
            >
              {{ scope.row.subject.reason }}
            </router-link>
          </template>
        </el-table-column>

        <el-table-column
          label="Когда"
          prop="created"
          width="150"
        />
        <el-table-column
          label="Описание"
          prop="description"
          width="200"
        />
      </el-table>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex'
import UserInfo from '../../components/common/UserInfo'
import WishInfo from '../../components/common/WishInfo'
import OfferInfo from '../../components/common/OfferInfo'
import ProductInfo from '@/components/common/ProductsInfo';

export default {
  metaInfo: {
    title: 'Логи',
  },
  components: {
    ProductInfo,
    OfferInfo,
    WishInfo,
    UserInfo,
    MainPanel,
  },
  data: () => ({
    data: [],
    listLink: '',
    loading: true,
  }),
  async created() {
    let data = []
    let listLink = ''
    const objectId = this.$route.params.id

    switch (this.$route.params.subject) {
      case 'admin':
        data = await this.getAdminActivity(objectId)
        listLink = 'AdminsList'
        break

      case 'user':
        data = await this.getUserActivity(objectId)
        listLink = 'UsersList'
        break

      case 'offer':
        data = await this.getActivityOverOffer(objectId)
        listLink = 'OffersList'
        break

      case 'complaint':
        data = await this.getActivityOverComplaint(objectId)
        listLink = 'ComplaintsList'
        break

      case 'ticket':
        data = await this.getActivityOverTicket(objectId)
        listLink = 'TicketList'
        break

      case 'overUser':
        data = await this.getActivityOverUser(objectId)
        listLink = 'UsersList'
        break

      case 'wish':
        data = await this.getActivityOverWish(objectId)
        listLink = 'WishesList'
        break

      default:
        break
    }

    this.listLink = listLink
    this.data = data.data
    this.loading = false
  },
  methods: {
    ...mapActions('logs', [
      'getAdminActivity',
      'getUserActivity',
      'getActivityOverOffer',
      'getActivityOverComplaint',
      'getActivityOverTicket',
      'getActivityOverUser',
      'getActivityOverWish',
    ]),
    getContragentType(val) {
      switch (val) {
        case 'admin':
          return 'Админ:'
        case 'user':
          return 'Пользователь:'
        case 'offer':
          return 'Предложение:'
        case 'complaint':
          return 'Жалоба:'
        case 'ticket':
          return 'Тикет:'
        case 'overUser':
          return 'Пользователь:'
        case 'wish':
          return 'Желание:'
        default:
          break
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

::v-deep .el-table
  td
    vertical-align: top

.contragent-label
  font-weight: bold
  margin-bottom: 10px
  color: $color-text-accent-customer

</style>
