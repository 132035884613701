import geoListPage from '@/pages/geo/ListPage'
import ViewCityPage from '@/pages/geo/ViewPage'


export default [
  {
    path: '/cities/list',
    name: 'GeoList',
    component: geoListPage,
    // meta: {
    //   requiresAuth: true,
    //   requiresPermissions: ['cities.list'],
    // },
  },
  {
    path: '/cities/:city_id',
    name: 'ViewCityPage',
    component: ViewCityPage,
  },
]
