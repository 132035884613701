<template>
  <el-table :data="list" stripe>
    <el-table-column label="Email" prop="email" width="350" />
    <el-table-column label="Роль" prop="role" width="140" />

    <el-table-column label="Права" min-width="400">
      <template slot-scope="scope">
        <el-tag
          v-for="permission in scope.row.permissions"
          :key="permission.name"
          disable-transitions
          size="small"
          type="info"
        >
          {{ permission.title }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column align="center" label="Активность" width="120">
      <template slot-scope="scope">
        <el-switch
          v-if="!isItMe(scope.row.id)"
          active-color="#67C23A"
          :value="scope.row.is_active"
          @change="onToggleStatus(scope.row)"
        />
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="200">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onOpenLog(scope.row.id)"
        >
          Логи
        </el-button>

        <el-button
          size="small"
          type="success"
          @click="onEdit(scope.row.id)"
        >
          <i class="far fa-edit" />
        </el-button>

        <el-button
          size="small"
          type="danger"
          @click="onDelete(scope.row.id)"
        >
          <i class="far fa-trash-alt" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'List',
  computed: {
    ...mapState('admin-management', {
      list: 'admins',
    }),
    ...mapGetters('auth', [
      'isItMe',
    ]),
  },
  methods: {
    ...mapActions('admin-management', [
      'deleteAdmin',
      'fetchAdmins',
      'enableAdmin',
      'disableAdmin',
    ]),
    onEdit(id) {
      this.$router.push({name: 'UpdateAdmin', params: {id}})
    },
    async onToggleStatus(item) {
      if (item.is_active) {
        await this.disableAdmin(item.id)
      } else {
        await this.enableAdmin(item.id)
      }
    },
    async onDelete(id) {
      try {
        const res = await this.$confirm('Вы действительно хотите удалить администратора?')
        if (res) {
          await this.deleteAdmin(id)
          await this.fetchAdmins()
        }
      } catch {
      }
    },
    onOpenLog(id) {
      this.$router.push({name: 'LogsList', params: {id, subject: 'admin'}})
    },
  },
}
</script>

<style lang="sass" scoped>

.el-tag
  margin-right: 7px
  margin-bottom: 7px

</style>
