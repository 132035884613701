import api from '@/utils/api';

export default {
  state: () => ({
    clients: null,
  }),
  mutations: {
  setClients(state, payload) {
      state.clients = payload;
    },
  },
  actions: {
    async fetchClients({ commit }, params) {
      // if (params?.search === '') {
      //   delete params.search;
      // }
      const res = await api.http.get('/admin/clients', { params });
      commit('setClients', res?.data);
    },
    async fetchClient(_, id) {
      const res = await api.http.get(`admin/clients/${id}`);
      return res?.data?.data;
    },
    async changeClientState(_, id) {
      const res = await api.http.get(`admin/clients/approve/${id}`);
      return res?.data?.data;
    },
    async deleteClient({ dispatch }, id) {
      const res = await api.http.delete(`admin/clients/${id}`);
      dispatch('fetchClients');
      return res?.data?.data;
    },
    async updatePhone({ dispatch }, { phone, id }) {
      const res = await api.http.put(`admin/clients/${id}/phone`, { phone });
      dispatch('fetchClients');
      return res?.data?.data;
    },
  },
  namespaced: true,
};
