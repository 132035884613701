<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/sass/index.sass'
import DefaultLayout from '@/layouts/DefaultLayout'
import AuthLayout from '@/layouts/AuthLayout'

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | Egolist Admin',
  },
  components: {
    DefaultLayout,
    AuthLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
  },
}
</script>
