import api from '@/utils/api'
import tickets from './tickets/tickets';
import problemTicket from './tickets/problem-tickets';

export default {
  modules: {
    tickets,
    problemTicket,
  },
  state: () => ({
    FAQList: null,
    ticketCategories: null,
  }),
  mutations: {
    setFAQList(state, payload) {
      state.FAQList = payload
    },
    setTicketCategories(state, payload) {
      state.ticketCategories = payload
    },
  },
  getters: {},
  actions: {
    async fetchFAQList({commit}) {
      const res = await api.http.get('admin/helpcenter/faq')
      commit('setFAQList', res?.data?.data)
    },

    async createFAQItem(_, form) {
      await api.http.post('admin/helpcenter/faq', form)
    },

    async updateFAQItem(_, form) {
      await api.http.put(`admin/helpcenter/faq/${form.id}`, form)
    },

    async deleteFAQItem(_, id) {
      await api.http.delete(`admin/helpcenter/faq/${id}`)
    },

    async getFAQItem(_, id) {
      const res = await api.http.get(`admin/helpcenter/faq/${id}`)
      return res?.data?.data
    },

    async fetchTicketCategories({commit}) {
      const res = await api.http.get('admin/helpcenter/categories')
      commit('setTicketCategories', res?.data?.data)
    },

    async createTicketCategory(_, form) {
      await api.http.post('admin/helpcenter/categories', form)
    },

    async updateTicketCategory(_, form) {
      await api.http.put(`admin/helpcenter/categories/${form.id}`, form)
    },

    async deleteTicketCategory(_, id) {
      await api.http.delete(`admin/helpcenter/categories/${id}`)
    },

    async getTicketCategory(_, id) {
      const res = await api.http.get(`admin/helpcenter/categories/${id}`)
      return res?.data?.data
    },
  },
  namespaced: true,
}
