import contractorsListPage from '@/pages/contractors/ListPage'
import viewContractorPage from '@/pages/contractors/ViewPage.vue';
import TagsPage from '@/pages/contractors/TagsPage.vue';

export default [
  {
    path: '/contractors/list',
    name: 'ContractorsList',
    component: contractorsListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contractors/view/:id',
    name: 'ViewContractor',
    component: viewContractorPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contractors/tags',
    name: 'Tags',
    component: TagsPage,
    meta: {
      requiresAuth: true,
    },
  },
]
