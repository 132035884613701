<template>
  <el-drawer
    size="50%"
    :visible.sync="isDrawerOpen"
    @closed="onClosed"
  >
    <div v-if="tender">
      <el-card
        class="box-card"
      >
        <el-descriptions border :column="1">
          <el-descriptions-item label="Пользователь">{{ tender.user.show_name }}</el-descriptions-item>
          <el-descriptions-item label="Категория">{{ tender.category.name }}</el-descriptions-item>
          <el-descriptions-item label="Населенный пункт">{{ tender.city.name }}</el-descriptions-item>
          <el-descriptions-item label="Область">{{ tender.city.region_name }}</el-descriptions-item>
          <el-descriptions-item label="Состояние">{{ tender.condition }}</el-descriptions-item>
          <el-descriptions-item label="Дата создание">{{ tender.created_at }}</el-descriptions-item>
          <el-descriptions-item label="Статус">
            <el-select
              v-if="tender"
              v-model="selectedState"
              placeholder="Select"
              @change="publishOrArchived"
            >
              <el-option
                v-for="state in states"
                :key="state.value"
                :label="state.label"
                :value="state.value"
              />
            </el-select>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
  </el-drawer>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ViewDrawer',
  data() {
    return {
      isDrawerOpen: false,
      tender: null,
      states: [
        {value: 'PUBLISHED', label:'PUBLISHED'},
        {value: 'ARCHIVED', label:'ARCHIVED'}],
        selectedState: '',
    };
  },
  async created() {
    if (this.$route.name === 'InternalWishesView') {
      this.isDrawerOpen = true;
      this.tender = await this.fetchInternalWish(this.$route.params.id);
      this.selectedState = this.tender.state
    }
  },
  methods: {
    ...mapActions('internalWishes', [
      'fetchInternalWish','publishInternalWish','hideInternalWish',
    ]),
    async open(tenderId) {
      this.isDrawerOpen = true;
      this.$router.push({ name: 'InternalWishesView', params: { id: tenderId } });
      this.tender = await this.fetchInternalWish(tenderId);
      this.selectedState = this.tender.state

    },
    onClosed() {
      this.$router.push({ name: 'InternalWishes' });
      this.tender = null;
    },
     async publishOrArchived() {
      this.selectedState === 'PUBLISHED' ? await this.publishInternalWish(this.tender.id) : await this.hideInternalWish(this.tender.id)
      await this.$emit('update')
         const h = this.$createElement;
         this.$notify({
           message: h('i', { style: 'color: teal' }, `Статус изменен на ${this.selectedState}`),
         });
    },
  },
}
</script>
