<template>
  <vue-editor
    v-bind="$attrs"
    :editor-toolbar="[['bold', 'italic', 'underline'], ['link'], ['image']]"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import {VueEditor} from 'vue2-editor'

export default {
  name: 'Redactor',
  components: {
    VueEditor,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass">
div.ql-editor
  font-size: 14px
  line-height: 1.4

.ql-container
  font-family: 'Montserrat', sans-serif !important

</style>
