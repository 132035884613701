<template>
  <div>
    <PageHeader page-name="Проблемные тикеты" />
    <MainPanel>
      <List v-loading="loading" :data="tickets" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import List from '@/components/help-center/tickets-problem/List'
import MainPanel from '@/components/common/MainPanel'
import PageHeader from '@/components/common/PageHeader';

export default {
  components: {
    MainPanel,
    List,
    PageHeader,
  },
  metaInfo: {
    title: 'Тикеты',
  },
  data: () => ({
    loading: false,
    tickets: [],
  }),
  async created() {
    this.loading = true
    this.tickets = await this.getProblemTickets()
    this.loading = false
  },
  methods: {
    ...mapActions('help-center/problemTicket', [
      'getProblemTickets',
    ]),
  },
}
</script>
