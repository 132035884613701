import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import helpCenter from '@/store/help-center'
import adminManagement from '@/store/admin-management'
import users from '@/store/users'
import clients from '@/store/clients';
import contractors from '@/store/contractors'
import tags from '@/store/tags'
import translations from '@/store/translations'
import wishes from '@/store/wishes'
import valueObjects from '@/store/value-objects'
import offers from '@/store/offers'
import complaints from '@/store/complaints'
import logs from '@/store/logs';
import cities from '@/store/geo/cities';
import regions from '@/store/geo/regions';
import statistics from '@/store/statistics/';
import trash from '@/store/wishes-trash/trash'
import offersTrash from '@/store/offers-trash/trash'
import internalUsers from '@/store/internal-users'
import internalWishes from '@/store/internal-wishes'
import products from '@/store/products'
import events from '@/store/events'
import topics from '@/store/topics'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: 'default-layout',
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload
    },
  },
  namespaced: true,
  modules: {
    auth,
    'help-center': helpCenter,
    'admin-management': adminManagement,
    users,
    contractors,
    clients,
    tags,
    translations,
    wishes,
    complaints,
    'value-objects': valueObjects,
    offers,
    logs,
    cities,
    regions,
    statistics,
    trash,
    offersTrash,
    internalUsers,
    internalWishes,
    products,
    events,
    topics,
  },
})
