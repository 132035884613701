<template>
  <div v-if="list">
    <el-table
      :data="list.data"
      :default-sort="{prop: 'date' }"
      style="width: 100%"
    >
      <el-table-column label="Изображение" prop="image_links" width="100">
        <template slot-scope="scope">
          <img alt="Изображение" class="image" :src="scope.row.image_links[0]">
        </template>
      </el-table-column>

      <el-table-column label="Название мероприятия" prop="title" />

      <el-table-column
        label="Название заведения"
        prop="place_name"
        width="160"
      />

      <el-table-column
        label="Дата проведения"
        prop="date"
        width="160"
      />

      <el-table-column
        label="Время проведения"
        prop="time"
        width="160"
      />

      <el-table-column
        label="Тип мероприятия"
        prop="event_type.title"
        width="160"
      />

      <el-table-column
        align="center"
        fixed="right"
        label="Изменить"
        width="120"
      >
        <template slot-scope="scope">
          <router-link :to="{name: 'EditEventPage', params: {id: scope.row.id}}">
            <el-button
              circle
              icon="el-icon-edit"
              size="small"
              type="primary"
            />
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        fixed="right"
        label="Удалить"
        width="120"
      >
        <template slot-scope="scope">
          <el-button
            circle
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="removeEvent(scope.row.id)"
          />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        fixed="right"
        label="Просмотр на сайте"
        width="170"
      >
        <template slot-scope="scope">
          <a :href="scope.row.frontend_url" target="_blank">
            <el-button
              v-show="scope.row.frontend_url"
              size="small"
              type="info"
            >
              <i class="fa fa-file" />
            </el-button>
          </a>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'EventsList',
  computed: {
    ...mapState('events', {list: state => state.events}),
  },
  methods: {
    ...mapActions('events', ['deleteEvent']),

    async removeEvent(id) {
        let confirmation = false
        try {
          confirmation = await this.$confirm('Вы действительно хотите удалить это мероприятие?', {
            cancelButtonText: 'Нет',
            confirmButtonText: 'Да',
            type: 'warning',
            center: true,
          })
        } catch (e) {
          console.error(e)
        }

        if (!confirmation) {
          return
        }
        try {
          await this.deleteEvent(id)
          await this.$message.success('Мероприятие успешно  удалено!');
        } catch (e) {
          await this.$message.error('Мероприятие НЕ удалось удалить!)');
        }
    },
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase
  white-space: pre-wrap
  height: auto
  line-height: 1.4
  padding: 5px

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0
.image
  width: 100px
  height: auto
</style>

