<template>
  <div class="login-page">
    <div class="logo-wrapper">
      <img alt="Logo" class="logo" src="~@/assets/img/main_logo.svg">
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        Administrator Login
      </div>
      <div class="panel-body">
        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm'

export default {
  name: 'LoginPage',
  components: {LoginForm},
  metaInfo: {
    title: 'Login',
  },
}
</script>

<style lang="scss" scoped>

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.panel {
  min-width: 400px;
}

.panel-heading {
  text-align: center;
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.alert {
  margin-bottom: 20px;
}

::v-deep .el-alert__title {
  font-size: 14px;
}

</style>
