import api from '@/utils/api'

export default {
  state: () => ({
    users: null,
    stateList: [
      {
        key: null,
        label: 'Любой',
      },
      {
        key: 'ACTIVE',
        label: 'Активные пользователи',
      },
      {
        key: 'INACTIVE',
        label: 'Неактивные пользователи',
      },
      {
        key: 'SUSPENDED',
        label: 'Приостановленные пользователи',
      },
    ],
    typeList: [
      {
        key: null,
        label: 'Любой',
      },
      {
        key: 'PERSONAL',
        label: 'Личный',
      },
      {
        key: 'BUSINESS',
        label: 'Бизнес',
      },
      {
        key: 'BUSINESS_REQUESTED',
        label: 'Запрошен бизнес',
      },
    ],
  }),
  mutations: {
    setUsers(state, payload) {
      state.users = payload
    },
    approveUser(state, id) {
      if (!state.users) return
      state.users.data = state.users.data.map(item => {
        if (item.id === id) {
          item.is_approved = true
        }
        return item
      })
    },
    discardUser(state, id) {
      if (!state.users) return
      state.users.data = state.users.data.map(item => {
        if (item.id === id) {
          item.is_approved = false
        }
        return item
      })
    },
  },
  getters: {},
  actions: {
    async fetchUsers({commit}, params) {
      if (params.search === '') {
        delete params.search
      }
      const res = await api.http.get('admin/users', {params})
      commit('setUsers', res?.data)
    },

    async fetchUser(_, id) {
      const res = await api.http.get(`admin/users/${id}`)
      return res?.data?.data
    },

    async approveUser({commit}, id) {
      commit('approveUser', id)
      await api.http.post(`admin/users/${id}/approve`)
    },

    async discardUser({commit}, id) {
      commit('discardUser', id)
      await api.http.post(`admin/users/${id}/discard`)
    },

    async fetchTimesOfBan() {
      try {
        const {data} = await api.http.get('admin/ban/get-available-time')
        return data.data
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async hardBanUser(_, {userId, ban_time}) {
        let {data} = await api.http.post(`admin/users/${userId}/hard-ban`, {ban_time})

        return data.data
    },

    async softBanUser(_, {userId, ban_time}) {
        let {data} = await api.http.post(`admin/users/${userId}/soft-ban`, {ban_time})

        return data.data
    },

    async unbanUser(_, userId) {
      let {data} = await api.http.post(`admin/users/${userId}/unban`)

      return data.data
    },
    async incrementUserFeatureType(_, {userId, feature_type}) {
      let {data} = await api.http.post(`admin/users/${userId}/${feature_type}/increment`)

      return data.data
    },

    async decrementUserFeatureType(_, {userId, feature_type}) {
      let {data} = await api.http.post(`admin/users/${userId}/${feature_type}/decrement`)

      return data.data
    },
  },
  namespaced: true,
}
