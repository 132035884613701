<template>
  <router-link class="wish-info" :to="{name: 'ViewProducts', params: {id: product.id}}">
    <div>
      {{ product.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ProductInfo',
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="sass" scoped>

.wish-info
  display: flex
  align-items: center
</style>
