<template>
  <div>
    <div class="title">
      404
    </div>
    <div class="sub-title">
      Страница не найдена
    </div>

    <router-link class="link" :to="{name: 'Home'}">
      на главную страницу
    </router-link>
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>

.title
  font-size: 200px
  color: #FFFFFF
  font-weight: bold
  text-align: center
  line-height: 1

.sub-title
  font-size: 40px
  color: #FFFFFF
  text-align: center
  margin-bottom: 20px

.link
  text-align: center
  display: block

</style>
