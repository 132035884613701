import api from '@/utils/api'

export default {
  actions: {
    async getTickets(_, filter) {
      try {
        const { data } = await api.http.get('admin/helpcenter/tickets', filter)
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getTicketById(_, ticketId) {
      try {
        const { data } = await api.http.get(`admin/helpcenter/tickets/${ticketId}`)
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    },

    async sendMessageToTicket(_, { ticketId, text, media }) {
      try {
        const { data } = await api.http.post(`admin/helpcenter/tickets/${ticketId}/message`, { text, media })
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    },

    async closeTicket(_, ticketId) {
      try {
        await api.http.patch(`admin/helpcenter/tickets/${ticketId}`)
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async attachTicket(_, ticketId) {
      try {
        await api.http.post(`admin/helpcenter/tickets/${ticketId}/attach`)
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async detachTicket(_, ticketId) {
      try {
        await api.http.post(`admin/helpcenter/tickets/${ticketId}/detach`)
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    
    async sendFile(_, file) {
      try {
        const form = new FormData
        form.append('file', file)

        const { data } = await api.http.post('admin/helpcenter/tickets/files/upload', form);
        return data.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
  namespaced: true,
}
