<template>
  <div>
    <PageHeader page-name="Пользователи">
      <template>
        <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel>
      </template>
    </PageHeader>

    <MainPanel>
      <List v-loading="loading" @sort="doSort" />

      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import List from '@/components/users/List'
import MainPanel from '@/components/common/MainPanel'
import FilterPanel from '@/components/common/FilterPanel'
import Filters from '@/components/users/Filters'
import PageHeader from '@/components/common/PageHeader';


export default {
  components: {Filters, FilterPanel, MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Пользователи',
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('users', [
      'users',
    ]),
    meta() {
      return this.users?.meta
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    ...mapActions('users', [
      'fetchUsers',
    ]),
    async getUsers() {
      this.loading = true
      await this.fetchUsers(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getUsers()
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.filterObject.page = 1
      this.getUsers()
    },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getUsers()
    },
  },
}
</script>
