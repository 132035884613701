import clientsListPage from '@/pages/clients/ListPage'; // створіть цей компонент
import viewClientPage from '@/pages/clients/ViewPage.vue'; // створіть цей компонент

export default [
  {
    path: '/clients/list',
    name: 'ClientsList',
    component: clientsListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/view/:id',
    name: 'ViewClient',
    component: viewClientPage,
    meta: {
      requiresAuth: true,
    },
  },
];
