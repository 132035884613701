import api from '@/utils/api'
import router from '@/router'

export default {
  state: () => ({
    user: null,
  }),
  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },
  getters: {
    isGuest: state => {
      return state.user === null
    },
    isItMe: state => {
      if (!state.user) return
      return userId => {
        return state.user.id === userId
      }
    },
  },
  actions: {
    async loadUser({commit, dispatch}) {
      try {
        const res = await api.http.get('admin/user/me')
        await commit('setUser', res?.data?.data)
      } catch (e) {
        dispatch('logout')
      }
    },

    logout({commit}) {
      api.clearToken()
      commit('setUser', null)
      router.push({name: 'Login'})
    },

    async login({dispatch}, form) {
      const result = await api.http.post('admin/auth/login', {...form})

      if (result?.data?.error) {
        return result?.data
      } else {
        await api.setToken(result?.data?.token)
        await dispatch('loadUser')
        router.push({name: 'Home'})
      }
    },

    async getUser() {
      const res = await api.http.get('user/getByToken')
      return res?.data
    },
  },
  namespaced: true,
}
