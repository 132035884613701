import api from '@/utils/api'
import categories from '@/store/wishes/categories'

export default {
  modules: {
    categories,
  },
  state: () => ({
    products: null,
    reasons: [],
    productStates: [],
  }),
  mutations: {
    setProducts(state, payload) {
      state.products = payload
    },
    setReasons(state, payload) {
      state.reasons = payload
    },
    setProductStates(state, payload) {
      state.productStates = payload
    },
  },
  getters: {
    getProductStateByKey: state => {
      return key => {
        return state.productStates.find(x => x.value === key).title
      }
    },
    productStatesTransformed: state => {
      return state.productStates.map(item => {
        return {
          key: item,
          label: item,
        }
      })
    },
    getTotalProducts(state){
      return state.products?.meta.total
    },
  },
  actions: {
    async fetchProducts({commit}, params) {
      const copy = {...params}
      if (!copy.search) delete copy.search
      if (!copy.state) delete copy.state
      const res = await api.http.get('/admin/products', {params: copy})

      commit('setProducts', res?.data)
    },

    async fetchProduct(_, id) {
      const res = await api.http.get(`admin/products/${id}`)
      return res?.data?.data
    },

    async approveProduct(_, id) {
      await api.http.post(`admin/products/${id}/approve`)
    },

    async discardProduct(_, {id, form}) {
      await api.http.post(`admin/products/${id}/discard`, form)
    },

    async fetchReasons({commit}, params) {
      const res = await api.http.get('admin/products/reasons', {params})
      commit('setReasons', res?.data?.data)
    },

    async fetchProductStates({commit}) {
      const res = await api.http.get('admin/products/states')
      commit('setProductStates', res?.data)
    },

    async takeMutex(_, id) {
      await api.http.post(`admin/products/${id}/mutex`)
    },

    async leaveMutex(_, id) {
      await api.http.delete(`admin/products/${id}/mutex`)
    },

    async updateProduct(_, {id, form}) {
      const formClone = {...form}
      formClone.images = formClone.images.map(x => x.id)
      if(formClone.category_id !== formClone.category[formClone.category.length -1] && Array.isArray(formClone.category)) {
        formClone.category_id = formClone.category[formClone.category.length -1]
      }
      await api.http.post(`admin/products/${id}`, formClone)

    },

    async getAdminStatuses() {
      const rsp = await api.http.get('admin/products/admin-statuses');
      return rsp.data;
    },

    async saveAdminStatus(_, {id, status}) {
      await api.http.post(`admin/products/${id}/change-status`, {'admin_status': status})
    },

    async deleteProduct(_, id) {
      return await api.http.delete(`admin/products/${id}`)
    },

    async transferProductToContractor(_, id) {
      let rsp = await api.http.post(`admin/products/${id}/transfer`);
      return rsp.data
    },

    async changeContractorContent(_, id) {
      let rsp = await api.http.post(`admin/products/${id}/change-content`);
      return rsp.data
    },
  },
  namespaced: true,
}
