<template>
  <div>
    <PageHeader page-name="Города">
      <template>
        <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel>
      </template>
    </PageHeader>

    <MainPanel>
      <List v-loading="loading" @sort="doSort" />
      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import List from '@/components/geo/List'
import MainPanel from '@/components/common/MainPanel'
import Filters from '@/components/geo/FilterGeo'
import FilterPanel from '@/components/common/FilterPanel'
import PageHeader from '@/components/common/PageHeader';



export default {
  components: {List, MainPanel, Filters, FilterPanel, PageHeader},

  metaInfo: {
    title: 'Города',
  },
  data(){
    return{
      loading:false,
      filterObject: {
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('cities', [
      'cities',
    ]),

    meta() {
      return this.cities?.meta
    },
  },
  mounted() {
    this.getCities()
    this.fetchRegions()
  },
  methods: {
    ...mapActions('cities', [
      'fetchCities',
    ]),
    ...mapActions('regions', [
      'fetchRegions',
    ]),
  async getCities() {
    this.loading = true
    await this.fetchCities(this.filterObject)
    this.loading = false
  },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getCities()
    },

    changePage(page) {
      this.filterObject.page = page
      this.getCities()
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.page = 1
      this.getCities()
    },
},


}
</script>

<style scoped>

</style>
