<template>
  <div>
    <el-drawer
      :append-to-body="true"
      :before-close="handleClose"
      direction="ltr"
      size="33%"
      :title="formTitle"
      :visible.sync="isDrawerOpen"
    >
      <div v-if="form" class="internal-form">
        <el-form
          ref="form"
          label-position="top"
          label-width="220px"
          :model="form"
        >
          <el-form-item label="Название типа мероприятия на украинском">
            <el-input v-model="form.title" @input="fieldChanged()" />
            {{ $_ErrorMixin_getError('title') }}
          </el-form-item>

          <el-form-item label="Название типа мероприятия на русском">
            <el-input v-model="form.title_ru" @input="fieldChanged()" />
            {{ $_ErrorMixin_getError('title_ru') }}
          </el-form-item>

          <el-form-item label="Сео заголовок типа мероприятия на украинском">
            <el-input v-model="form.seo_title" @input="fieldChanged()" />
            {{ $_ErrorMixin_getError('seo_title') }}
          </el-form-item>

          <el-form-item label="Сео заголовок типа мероприятия на русском">
            <el-input v-model="form.seo_title_ru" @input="fieldChanged()" />
            {{ $_ErrorMixin_getError('seo_title_ru') }}
          </el-form-item>

          <el-form-item label="Сео текст мероприятия на украинском">
            <el-input
              v-model="form.seo_text"
              rows="5"
              type="textarea"
              @input="fieldChanged()"
            />
            {{ $_ErrorMixin_getError('seo_text') }}
          </el-form-item>

          <el-form-item label="Сео текст типа мероприятия на русском">
            <el-input
              v-model="form.seo_text_ru"
              rows="5"
              type="textarea"
              @input="fieldChanged()"
            />
            {{ $_ErrorMixin_getError('seo_text_ru') }}
          </el-form-item>
        </el-form>
        <el-button type="success" @click="save">
          Сохранить
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import ErrorMixin from '@/utils/ErrorMixin';
import {mapActions} from 'vuex';

const defaultValues = {
  title: '',
  title_ru: '',
  seo_title: '',
  seo_title_ru: '',
  seo_text: '',
  seo_text_ru: '',
};

export default {
  name: 'EventTypeForm',
  mixins: [ErrorMixin],
  data() {
    return {
      form: null,
      formTitle: null,
      isDrawerOpen: false,
      isNewEventType: true,
      formChanged: false,
    };
  },
  methods:{
    ...mapActions('events', ['createEventType', 'editEventType']),
    async onSubmit() {
    },

    async openDrawer(eventTypeToEdit = null) {
      this.form =  eventTypeToEdit ? {...eventTypeToEdit} : {...defaultValues}
      this.isNewEventType = !eventTypeToEdit
      this.isDrawerOpen = true
      this.formTitle = eventTypeToEdit ? 'Редактирование типа мероприятия'  : 'Создание типа мероприятия'
      },

    async save(){
      if (this.isNewEventType) {
        await this.createNewEventType()
      } else {
        await this.editType()
      }
    },

    async editType() {
      try {
        await this.editEventType(this.form)
        await this.$message.success('Тип мероприятия успешно изменен');
        this.closeDrawer()
        this.form = {...defaultValues}
      } catch (e) {
        this.$_ErrorMixin_handleError(e)
        await this.$message.error('Ошибка!');
      }
    },

    async createNewEventType() {
      try {
        await this.createEventType(this.form)
        await this.$message.success('Новый тип мероприятия создан');
        this.closeDrawer()
        this.form = {...defaultValues}
      } catch (e) {
        this.$_ErrorMixin_handleError(e)
        await this.$message.error('Ошибка!');
      }
    },

    closeDrawer() {
      this.isDrawerOpen = false
    },
    handleClose(done) {
      if(this.formChanged) {
        this.$confirm('В форме остались несохраненные данные. Выйти без сохранения?')
          .then(() => {
            done();
            this.formChanged = false;
          })
          .catch(() => {});
      } else {
        done();
        this.formChanged = false;
      }
    },
    fieldChanged() {
      this.formChanged = true;
    },
  },
  }
</script>
