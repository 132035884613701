<template>
  <div class="limit-block">
    <MainPanel
      v-loading="loading"
      class="limit-block__user-data"
      element-loading-spinner="el-icon-loading"
      element-loading-text="Loading..."
    >
      <h2>Управления лимитами</h2>
      <table v-if="user">
        <tr>
          <td><strong>Активный пакет пользователя</strong></td>
          <td>{{ user.package }}</td>
        </tr>
        <tr>
          <td><strong>Доступное количество тендеров</strong></td>
          <td>{{ user.limits.total.ACTIVE_WISH.count }}</td>
        </tr>
        <tr>
          <td><strong>Доступное количество разовых тендеров </strong></td>
          <td>{{ user.limits.one_time.ACTIVE_WISH.count }}</td>
          <td>
            <el-button
              class="limit-block__button"
              type="success"
              @click="toIncrease(oneTimeActiveWishType)"
            >
              +
            </el-button>
          </td>
          <td>
            <el-button
              class="limit-block__button"
              type="danger"
              @click="toDecrease(oneTimeActiveWishType)"
            >
              -
            </el-button>
          </td>
        </tr>
        <tr>
          <td><strong>Доступное количество предложений</strong></td>
          <td>{{ user.limits.total.ACTIVE_OFFER.count }}</td>
        </tr>
        <tr>
          <td><strong>Доступное количество разовых предложений</strong></td>
          <td>{{ user.limits.one_time.ACTIVE_OFFER.count }}</td>
          <td>
            <el-button
              class="limit-block__button"
              type="success "
              @click="toIncrease(oneTimeActiveOfferType)"
            >
              +
            </el-button>
          </td>
          <td>
            <el-button
              class="limit-block__button"
              type="danger"
              @click="toDecrease(oneTimeActiveOfferType)"
            >
              -
            </el-button>
          </td>
        </tr>
      </table>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex';

export default {
  name: 'LimitManagement',
  components: {MainPanel},

  data() {
    return {
      loading: false,
      user: null,
      oneTimeActiveWishType: null,
      oneTimeActiveOfferType: null,
      oneTimeActiveWishCount: null,
      oneTimeActiveOfferCount: null,
    }
  },
 async created() {
   await this.getUser()
  },
  methods: {
    ...mapActions('users', [
      'fetchUser',
      'incrementUserFeatureType',
      'decrementUserFeatureType',
    ]),
    async toIncrease(featureType) {
      this.loading = true
      await this.incrementUserFeatureType({userId: this.user.id, feature_type: featureType})
      this.loading = false
      await this.getUser()
    },
    async toDecrease(featureType) {
      if (featureType === this.oneTimeActiveWishType && !this.oneTimeActiveWishCount) return
      if (featureType === this.oneTimeActiveOfferType && !this.oneTimeActiveOfferCount) return

      this.loading = true
      await this.decrementUserFeatureType({userId: this.user.id, feature_type: featureType})
      this.loading = false
      await this.getUser()
    },
    async getUser() {
      this.user = await this.fetchUser(this.$route.params.id)
      this.oneTimeActiveWishType = this.user.limits.one_time.ACTIVE_WISH.feature_type
      this.oneTimeActiveOfferType = this.user.limits.one_time.ACTIVE_OFFER.feature_type
      this.oneTimeActiveWishCount = this.user.limits.one_time.ACTIVE_WISH.count
      this.oneTimeActiveOfferCount = this.user.limits.one_time.ACTIVE_OFFER.count
    },

  },
}

</script>

<style lang="sass" scoped>

.limit-block
  margin-top: 20px

.limit-block__button
  display: flex
  justify-content: center
  align-items: center
  width: 70px
  height: 30px

.limit-block__user-data
  td
    padding: 10px 10px
    border-bottom: 1px solid #f1f1f1

  tr
    &:last-of-type
      td
        border-bottom: none
</style>
