<template>
  <el-table
    :data="list && list.data"
    :default-sort="{prop: 'created_at', order: 'descending'}"
    :row-class-name="tableRowClassName"
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column label="Изображение" width="110">
      <template slot-scope="scope">
        <TenderImageColumn :tender="scope.row" />
      </template>
    </el-table-column>

    <el-table-column label="Название" prop="name">
      <template slot-scope="scope">
        <WishInfo :wish="scope.row" />
      </template>
    </el-table-column>

    <el-table-column
      label="Дата создания"
      prop="created_at"
      sortable="custom"
      width="160"
    />

    <el-table-column label="Пользователь" prop="name">
      <template slot-scope="scope">
        <UserCard :user="scope.row.user" />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Статус"
      width="120"
    >
      <template slot-scope="scope">
        <el-tag
          class="type-tag"
          size="small"
          :type="getStatusTagType(scope.row.state)"
        >
          {{ scope.row.state && scope.row.state.replace('_', ' ') }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      fixed="right"
      label="Просмотр в админке"
      width="170"
    >
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      fixed="right"
      label="Просмотр на сайте"
      width="170"
    >
      <template slot-scope="scope">
        <a :href="scope.row.url" target="_blank">
          <el-button
            v-show="scope.row.url"
            size="small"
            type="info"
          >
            <i class="fa fa-file" />
          </el-button>
        </a>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Количество предложений"
      prop="offers_count"
      width="130"
    />
  </el-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import WishInfo from '../common/WishInfo'
import UserCard from '@/components/user-card/UserCard';
import TenderImageColumn from '@/components/column-components/TenderImageColumn';

export default {
  name: 'List',
  components: {TenderImageColumn, UserCard, WishInfo},
  computed: {
    ...mapState('wishes', {
      list: 'wishes',
    }),
    ...mapGetters('wishes', [
      'getWishStateByKey',
    ]),
    ...mapGetters('value-objects', [
      'getStatusTagType',
    ]),
  },
  created() {
    this.fetchWishStates()
  },
  methods: {
    ...mapActions('wishes', [
      'fetchWishStates',
    ]),
    onView(id) {
      this.$router.push({name: 'ViewWish', params: {id}})
    },
    onSortChange(val) {
      if (val.prop === 'created_at') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'oldest' : 'newest',
        })
      }
    },
    tableRowClassName({row}) {
      if (row.type === 'internal') {
        return 'warning-row';
      }
      return '';
    },
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase
  white-space: pre-wrap
  height: auto
  line-height: 1.4
  padding: 5px

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0

</style>
