<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <el-form-item label="Имя или email" prop="login">
      <el-input
        v-model="form.login"
        autocomplete="name"
      />
    </el-form-item>

    <el-form-item label="Пароль" prop="password">
      <el-input
        v-model="form.password"
        autocomplete="password"
        type="password"
      />
    </el-form-item>

    <el-alert
      v-if="errorMessage"
      class="alert"
      :closable="false"
      show-icon
      :title="errorMessage"
      type="error"
    />

    <div class="text-right">
      <el-button native-type="submit" type="primary">
        Войти
      </el-button>
    </div>
  </el-form>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      errorMessage: null,
      form: {
        login: '',
        password: '',
      },
      rules: {
        login: [
          {required: true, message: 'Введите имя пользователя', trigger: 'change'},
        ],
        password: [
          {required: true, message: 'Введите пароль', trigger: 'change'},
        ],
      },
    }
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    onSubmit() {
      this.errorMessage = null
      this.$refs.form.validate(async valid => {
        if (valid) {
          const res = await this.login(this.form)
          if (res?.error) {
            this.errorMessage = res?.error
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.alert {
  margin-bottom: 20px;
}

</style>
