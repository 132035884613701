<template>
  <div>
    <PageHeader page-name="Удаленые тендеры">
      <template>
        <FilterPanel>
          <FilterWishesTrash @filter="doFilter" />
        </FilterPanel>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-if="wishesTrash.data" v-loading="loading" :data="wishesTrash.data" />
      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>

<script>
import List from '@/components/wishes-trash/List'
import {mapActions} from 'vuex';
import MainPanel from '@/components/common/MainPanel'
import FilterPanel from '@/components/common/FilterPanel'
import FilterWishesTrash from '@/components/wishes-trash/FilterWishesTrash'
import PageHeader from '@/components/common/PageHeader';


export default {
  name: 'ListPage',
  components: {FilterWishesTrash, FilterPanel, List, MainPanel, PageHeader},
  data(){
    return{
      loading:false,
      filterObject: {
        page: 1,
      },
      wishesTrash:[],
    }
  },

  computed:{
    meta() {
      return this.wishesTrash?.meta
    },

  },

 async created() {
    const res = await this.fetchWishesTrash(this.filterObject)
    this.wishesTrash = res
 },
  methods:{
    ...mapActions('trash', [
      'fetchWishesTrash',
    ]),

    async getWishesTrash() {
      this.loading = true
      this.wishesTrash = await this.fetchWishesTrash(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getWishesTrash()
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.page = 1
      this.getWishesTrash()
    },

  },
}


</script>

<style scoped>

</style>
