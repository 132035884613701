import api from '@/utils/api'

export default {
  state: () => ({
    admins: null,
    roles: [],
    permissions: [],
  }),
  mutations: {
    setAdmins(state, payload) {
      state.admins = payload
    },
    setRoles(state, payload) {
      state.roles = payload
    },
    setPermissions(state, payload) {
      state.permissions = payload
    },
    enableAdmin(state, id) {
      state.admins = state.admins.map(item => {
        if (item.id === id) {
          item.is_active = true
        }
        return item
      })
    },
    disableAdmin(state, id) {
      state.admins = state.admins.map(item => {
        if (item.id === id) {
          item.is_active = false
        }
        return item
      })
    },
  },
  getters: {},
  actions: {
    async fetchAdmins({commit}) {
      const res = await api.http.get('admin/admins')
      commit('setAdmins', res?.data?.data)
    },

    async createAdmin(_, form) {
      await api.http.post('admin/admins', form)
    },

    async updateAdmin(_, form) {
      const formClone = {...form}
      if (!form.password && !form.password_confirmation) {
        delete formClone.password
        delete formClone.password_confirmation
      }
      await api.http.put(`admin/admins/${form.id}`, formClone)
    },

    async deleteAdmin(_, id) {
      await api.http.delete(`admin/admins/${id}`)
    },

    async getAdmin(_, id) {
      const res = await api.http.get(`admin/admins/${id}`)
      return res?.data?.data
    },

    async fetchRoles({commit}) {
      const res = await api.http.get('admin/admins/roles')
      commit('setRoles', res?.data?.data)
    },

    async fetchPermissions({commit}) {
      const res = await api.http.get('admin/admins/roles/permissions')
      commit('setPermissions', res?.data?.data)

      return res?.data?.data
    },

    async enableAdmin({commit}, id) {
      commit('enableAdmin', id)
      await api.http.put(`admin/admins/${id}/enable`)
    },

    async disableAdmin({commit}, id) {
      commit('disableAdmin', id)
      await api.http.put(`admin/admins/${id}/disable`)
    },
  },
  namespaced: true,
}
