<template>
  <el-dialog
    :before-close="beforeClose"
    title="Выберите причину"
    :visible.sync="dialogVisible"
    width="400px"
    @open="afterOpen"
  >
    <p>Укажите причину, по которой вы отклоняете это Желание</p>

    <el-form
      ref="form"
      class="demo-form-inline"
      :model="form"
      :rules="rules"
    >
      <el-form-item prop="reason.type">
        <el-select
          v-model="form.reason.type"
          placeholder="Выберите из списка"
        >
          <el-option
            v-for="reason in reasons"
            :key="reason.type"
            :label="reason.title"
            :value="reason.type"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.reason.type === 'CUSTOM'" prop="reason.text">
        <el-input
          v-model="form.reason.text"
          placeholder="Опишите причину"
          :rules="form.reason.type === 'CUSTOM' ? rules.reason.text : [{ required: false, trigger: 'blur' }]"
          type="textarea"
        />
      </el-form-item>

      <div class="dialog-footer">
        <el-button @click="close">
          Отменить
        </el-button>
        <el-button type="primary" @click="onSubmit">
          Подтвердить
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'CheckReasonModal',
  data() {
    return {
      dialogVisible: false,
      form: {
        reason: {
          type: null,
          text: null,
        },
      },
      rules: {
        reason: {
          type: [
            {required: true, message: 'Выберите причину', trigger: 'change'},
          ],
          text: [
            {required: true, message: 'Введите сообщение', trigger: 'blur'},
          ],
        },
      },
    }
  },
  computed: {
    ...mapState('wishes', [
      'reasons',
    ]),
  },
  methods: {
    ...mapActions('wishes', [
      'fetchReasons',
    ]),
    open() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    afterOpen() {
      this.fetchReasons()
    },
    beforeClose() {
      this.$refs.form.resetFields()
      this.close()
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.close()
          this.$emit('check', this.form)
        } else {
          return false
        }
      })
    },
  },
}
</script>
