<template>
  <div>
    <div class="title-bar">
      <h1>Тикет</h1>
      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: 'TicketList'}">
          <el-button type="info">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>
        <router-link class="button-wrapper-link" :to="{name: 'LogsList', params: {id: ticket && ticket.id, subject: 'ticket'}}">
          <el-button type="info">
            <i class="fas fa-info-circle" /> Действия над тикетом
          </el-button>
        </router-link>
        <el-button type="danger" @click="handleCloseTicket">
          <i class="el-icon-close" />
          Закрыть
        </el-button>
        <el-button type="success" @click="handleAttachTicket">
          Закрепить
        </el-button>
        <el-button type="warning" @click="handleDetachTicket">
          Открепить
        </el-button>
      </div>
    </div>

    <MainPanel v-loading="loading" class="panel--mb">
      <table v-if="ticket" class="key-values-table">
        <tr>
          <td><strong>ID</strong></td>
          <td>{{ ticket && ticket.id }}</td>
        </tr>
        <tr>
          <td><strong>Категория</strong></td>
          <td>{{ ticket && ticket.category.name }}</td>
        </tr>
        <tr>
          <td><strong>Создано</strong></td>
          <td>{{ ticket && ticket.created_at }}</td>
        </tr>
        <tr>
          <td><strong>Статус</strong></td>
          <td>{{ ticket && ticket.status }}</td>
        </tr>
        <tr>
          <td><strong>Пользователь</strong></td>
          <td>{{ ticket && ticket.user }}</td>
        </tr>
      </table>
    </MainPanel>

    <MainPanel v-loading="loading">
      <div v-if="ticket" class="chat">
        <p class="chat__title">
          Сообщения
        </p>
        <div ref="chatContainer" class="chat__container">
          <Message 
            v-for="message of ticket.messages"
            :key="message.id"
            :message="message"
          />
        </div>
        <div class="chat__controls">
          <el-input
            v-model="text"
            :autosize="{ minRows: 2, maxRows: 4}"
            class="chat__input"
            resize="none"
            type="textarea"
          />
          <label class="chat__file" for="file-input">
            <i class="el-icon-document chat__file-icon" />            
            <input
              id="file-input"
              accept="image/png, image/gif, image/jpeg"
              class="chat__file-input"
              type="file"
              @change="handleSendFile"
            >
          </label>
          <el-button type="primary" @click="handleSendMessageToTicket">
            Отправить
          </el-button>
        </div>
      </div>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import Message from '@/components/help-center/tickets/Message';
import { mapActions } from 'vuex';

export default {
  metaInfo: {
    title: 'Тикет',
  },
  components: { 
    MainPanel,
    Message,
  },
  data() {
    return {
      ticket: null,
      loading: true,
      timeout: null,
      text: '',
      media: [],
    };
  },
  watch: {
    async ticket() {
      this.timeout = setTimeout(() => {
        const container = this.$refs.chatContainer;
        if (!container) return;
        container.scrollTop = container.scrollHeight
      }, 100)
    },
  },
  async mounted() {
    await this.getTicket()
    this.loading = false;
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
  methods: {
    ...mapActions('help-center/tickets', [
      'getTicketById',
      'closeTicket',
      'attachTicket',
      'detachTicket',
      'sendMessageToTicket',
      'sendFile',
    ]),
    async handleSendMessageToTicket() {
      const message = await this.sendMessageToTicket({ ticketId: this.$route.params.id, text: this.text, media: this.media });
      if (message) {
        this.text = '';
        this.ticket.messages.push(message);
      }
    },
    async handleSendFile(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      const res = await this.sendFile(file);
      this.media.push(res.id);
      this.handleSendMessageToTicket();
    },
    async handleCloseTicket() {
      const res = await this.closeTicket(this.$route.params.id);
      if (!res) return;
      await this.getTicket()
      this.$message({
        message: 'Тикет успешно закрыт.',
        type: 'success',
      });
    },
    async handleAttachTicket() {
      const res = await this.attachTicket(this.$route.params.id);
      if (!res) return;
      await this.getTicket();
      this.$message({
        message: 'Тикет успешно закреплен.',
        type: 'success',
      });
    },
    async handleDetachTicket() {
      const res = await this.detachTicket(this.$route.params.id);
      if (!res) return;
      await this.getTicket();
      this.$message({
        message: 'Тикет успешно откреплен.',
        type: 'success',
      });
    },
    async getTicket() {
      this.ticket = await this.getTicketById(this.$route.params.id);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.panel--mb
  margin-bottom: 25px

.chat__container
    height: 400px
    overflow: scroll
  
.chat__title
    font-size: 26px
    font-weight: bold

.chat__controls
    display: grid
    grid-template-columns: 4fr 80px 1fr
    grid-gap: 10px
    padding-top: 20px

.chat__file
    background-color: $color-info
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    color: $color-foreground-text
    height: 100%
    cursor: pointer
.chat__file-icon
      font-size: 20px
.chat__file-input
      display: none
.key-values-table
  width: 100%
</style>
