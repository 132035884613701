import ListPage from '@/pages/internal/wishes/ListPage';
import ViewPage from '@/pages/internal/wishes/ViewPage';

export default [
  {
    path: '/internal/wishes',
    name: 'InternalWishes',
    component: ListPage,
  },
  {
    path: '/internal/wishes/create',
    name: 'InternalWishesCreate',
    component: ListPage,
  },
  {
    path: '/internal/wishes/edit/:id',
    name: 'InternalWishesEdit',
    component: ListPage,
  },
  {
    path: '/internal/wishes/view/:id',
    name: 'InternalWishesView',
    component: ListPage,
  },
  {
    path: '/internal/wishes/:id',
    name: 'InternalWish',
    component: ViewPage,
  },

]
