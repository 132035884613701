import api from '@/utils/api'

export default {
  state: () => ({
    tags: [],
  }),
  mutations: {
    setTags(state, tags) {
      state.tags = tags;
    },
  },
  getters: {},
  actions: {
    async fetchTags({commit}) {
      const response = await api.http.get('/admin/tags');
      const tags = response.data; // Предполагается, что данные приходят в свойстве `data`
      commit('setTags', tags); // Сохраняем данные в состоянии через мутацию
    },
  },
  namespaced: true,
}
