import WishesTrash from '@/pages/wishes-trash/ListPage'
import ViewTrashPage from '@/pages/wishes-trash/ViewPage'


export default [
  {
    path: '/wishes/trash',
    name: 'WishesTrash',
    component: WishesTrash,
  },

  {
    path: '/wishes/trash/:id',
    name: 'ViewWishTrash',
    component: ViewTrashPage,
  },
]
