<template>
  <div class="panel panel-default">
    <div v-if="title" class="panel-heading">
      <div class="panel-title">
        {{ title }}
      </div>
    </div>

    <div class="panel-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>

.panel
  margin-top: 20px
  min-height: 100px

</style>
