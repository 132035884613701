<template>
  <el-form
    ref="form"
    class="filter-form"
    label-position="top"
    :model="filters"
  >
    <el-form-item label="Поиск">
      <el-input
        v-model="filters.search"
        v-debounce="doFilter"
        placeholder="Поиск"
        prefix-icon="el-icon-search"
      />
    </el-form-item>

    <el-form-item label="Статус">
      <el-select
        v-model="filters.state"
        class="multiselect"
        @change="doFilter"
      >
        <el-option
          v-for="state in [{key: null, label: 'Любой'}, ...offersStatesTransformed]"
          :key="state.key"
          :label="state.label"
          :value="state.key"
        />
      </el-select>
    </el-form-item>

    <el-button
      v-if="isFiltersChanged"
      type="gray"
      @click="clearFilters"
    >
      Сбросить фильтры
    </el-button>
  </el-form>
</template>

<script>
import {mapGetters} from 'vuex'

const defaultFilterValues = {
  search: '',
  state: null,
}

export default {
  name: 'Filters',
  data() {
    return {
      filters: {...defaultFilterValues},
      originFilters: {...defaultFilterValues},
    }
  },
  computed: {
    ...mapGetters('offers', [
      'offersStatesTransformed',
    ]),
    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
  },
  methods: {
    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultFilterValues}
      this.$emit('filter', this.filters)
    },
  },
}
</script>

<style lang="sass" scoped>

.multiselect
  min-width: 250px

</style>
