<template>
  <el-table
    :data="list && list.data"
    :default-sort="{prop: 'created_at', order: 'descending'}"
    :row-class-name="tableRowClassName"
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column label="Изображение" width="110">
      <template slot-scope="scope">
        <TenderImageColumn :tender="scope.row" />
      </template>
    </el-table-column>

    <el-table-column label="Название" prop="name">
      <template slot-scope="scope">
        <ProductInfo :product="scope.row" />
      </template>
    </el-table-column>

    <el-table-column
      label="Дата создания"
      prop="created_at"
      sortable="custom"
      width="160"
    />

    <el-table-column label="Пользователь" prop="name">
      <template slot-scope="scope">
        <UserCard :user="scope.row.user" />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Статус"
      width="120"
    >
      <template slot-scope="scope">
        <el-tag
          class="type-tag"
          size="small"
          :type="getStatusTagType(scope.row.state)"
        >
          {{ scope.row.state && scope.row.state.replace('_', ' ') }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      fixed="right"
      label="Просмотр в админке"
      width="170"
    >
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      fixed="right"
      label="Просмотр на сайте"
      width="170"
    >
      <template slot-scope="scope">
        <a :href="scope.row.frontend_url" target="_blank">
          <el-button
            v-show="scope.row.frontend_url"
            size="small"
            type="info"
          >
            <i class="fa fa-file" />
          </el-button>
        </a>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Админ. статус"
      width="170"
    >
      <template slot-scope="scope">
        <el-dropdown
          class="btn-item"
          trigger="click"
          @command="(command) => changeStatus(command, scope.row)"
        >
          <span class="el-dropdown-link">
            {{ scope.row.admin_status || 'Выберите статус' }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key) in adminStatuses"
              :key="key"
              :command="value"
            >
              {{ value }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import UserCard from '@/components/user-card/UserCard';
import TenderImageColumn from '@/components/column-components/TenderImageColumn';
import ProductInfo from '@/components/common/ProductsInfo.vue';

export default {
  name: 'List',
  components: {ProductInfo, TenderImageColumn, UserCard},
  data() {
    return {
      editMode: false,
      adminStatuses: {'DEFAULT': 'Без статусу'},
      adminStatusesLoaded: false,
    }
  },
  computed: {
    ...mapState('products', {
      list: 'products',
    }),
    ...mapGetters('products', [
      'getProductStateByKey',
    ]),
    ...mapGetters('value-objects', [
      'getStatusTagType',
    ]),
  },
  created() {
    this.loadAdminStatuses()
    this.fetchProductStates()
  },
  methods: {
    ...mapActions('products', [
      'fetchProductStates',
      'getAdminStatuses',
      'saveAdminStatus',
    ]),
    async loadAdminStatuses() {
      try {
        const statuses = await this.getAdminStatuses()
        this.adminStatuses = {...this.adminStatuses, ...statuses}
        this.adminStatusesLoaded = true
      } catch (error) {
        console.error('Error loading admin statuses:', error)
        this.adminStatusesLoaded = false
      }
    },
    onView(id) {
      this.$router.push({name: 'ViewProducts', params: {id}})
    },
    onSortChange(val) {
      if (val.prop === 'created_at') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'oldest' : 'newest',
        })
      }
    },
    tableRowClassName({row}) {
      if (row.type === 'internal') {
        return 'warning-row';
      }
      return '';
    },

    changeStatus(status, row) {
      if (status === 'Без статусу') {
        status = null;
      }
      this.saveAdminStatus({id: row.id, status: status})
        .then(() => {
          this.$set(row, 'admin_status', status)

          this.$message({
            message: 'Статус успешно обновлен',
            type: 'success',
          })
        })
        .catch(error => {
          console.error('Ошибка при обновлении статуса:', error)
          this.$message({
            message: 'Ошибка при обновлении статуса',
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase
  white-space: pre-wrap
  height: auto
  line-height: 1.4
  padding: 5px

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0

</style>
