<template>
  <el-container class="site-wrapper">
    <TheSidebar />

    <el-container class="content-area-wrapper">
      <TheHeader />

      <el-main class="main">
        <slot />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {mapGetters} from 'vuex'
import TheSidebar from '@/components/common/TheSidebar'
import TheHeader from '@/components/common/TheHeader'

export default {
  name: 'DefaultLayout',
  components: {TheHeader, TheSidebar},
  computed: {
    ...mapGetters('auth', ['isGuest']),
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.site-wrapper
  min-height: 100vh
  background: $color-bg

.content-area-wrapper
  flex-direction: column

.main
  padding: 30px

</style>
