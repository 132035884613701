<template>
  <el-table :data="data" stripe style="width: 100%">
    <el-table-column label="Пользователь" prop="ticket">
      <template slot-scope="scope">
        {{ scope.row.ticket.user }}
      </template>
    </el-table-column>
    <el-table-column label="Статус" prop="status" />
    <el-table-column label="Причина" prop="reason" />
    <el-table-column label="Категория" prop="ticket">
      <template slot-scope="scope">
        {{ scope.row.ticket.category }}
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>

export default {
  name: 'List',
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    onView(id) {
      this.$router.push({name: 'ViewProblemTicket', params: {id}})
    },
  },
}
</script>
