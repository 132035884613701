<template>
  <div>
    <div class="title-bar">
      <h1>{{ isUpdate ? 'Редактировать Администратора' : 'Создать Администратора' }}</h1>

      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: 'AdminsList'}">
          <el-button type="info">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>

        <el-button type="primary" @click="$refs.form.onSubmit()">
          Сохранить
        </el-button>

        <router-link v-if="isUpdate" class="button-wrapper-link" :to="{name: 'LogsList', params: {id: this.$route.params && this.$route.params.id, subject: 'admin'}}">
          <el-button type="info">
            <i class="fas fa-info-circle" /> Действия Админа
          </el-button>
        </router-link>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <Form ref="form" />
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/admin-management/Form'

export default {
  name: 'CreateUpdate',
  metaInfo: {
    title: 'Создать/Редактировать Администратора',
  },
  components: {Form},
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateAdmin'
    },
  },
}
</script>

