<template>
  <div>
    <EventForm />
  </div>
</template>

<script>


import EventForm from '../../components/events/EventForm.vue';

export default {
  components: {EventForm},
}
</script>
