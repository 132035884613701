<template>
  <div>
    <PageHeader page-name="Клиенты">
      <template>
        <!-- <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel> -->
      </template>
    </PageHeader>

    <MainPanel>
      <List v-loading="loading" @openModal="openModal" @sort="doSort" />

      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
    <ChangePhoneNumberModal
      :id="selectedId"
      :phone="selectedPhone"
      :visible="visibleCheckModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import List from '@/components/clients/List'
import MainPanel from '@/components/common/MainPanel'
// import FilterPanel from '@/components/common/FilterPanel'
// import Filters from '@/components/users/Filters'
import PageHeader from '@/components/common/PageHeader';
import ChangePhoneNumberModal from '../../components/clients/ChangePhoneNumberModal.vue';


export default {
  components: {ChangePhoneNumberModal,  MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Клиенты',
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
      selectedPhone: '',
      selectedId: '',
      visibleCheckModal: false,
    }
  },
  computed: {
    ...mapState('clients', [
      'clients',
    ]),
    meta() {
      return this.clients?.meta
    },
  },
  mounted() {
    this.getClients()
  },
  methods: {
    ...mapActions('clients', [
      'fetchClients',
    ]),
    async getClients() {
      this.loading = true
      await this.fetchClients(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getClients()
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.filterObject.page = 1
      this.getClients()
    },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getClients()
    },
    openModal({phone, id}) {
      console.log('phone', phone, 'id', id)
      this.selectedPhone = phone?.replace(/ /g, '') || '';
      this.selectedId = id;
      this.visibleCheckModal = true
    },
    closeModal() {
      this.visibleCheckModal = false
    },
  },
}
</script>
