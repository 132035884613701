import api from '@/utils/api'

export default {
  state: () => ({
    internalUsers: null,
}),
  mutations: {
    setInternalUsers(state, payload){
      state.internalUsers = payload
    },
  },
  actions: {
    async fetchInternalUsers({commit},params) {
      const res = await api.http.get('/admin/users/internal', {params})
      if (res.data) {
        commit('setInternalUsers', res.data)
      }
    },

    async fetchInternalUserForDropdown() {
      const res = await api.http.get('/admin/users/internal/internal-users-list')
      return res.data.data
    },

    async fetchInternalUser(_, id) {
      const res = await api.http.get(`/admin/users/internal/${id}`)
      return res?.data.data
    },

    async fetchPackagesType(_, type) {
      const res = await api.http.get(`/admin/users/internal/packages?account_type=${type}`)
      return res?.data?.data
    },

    async createUser(_, form) {
      await api.http.post('/admin/users/internal', form)
    },

    async uploadImage(_, file) {
      const res =  await api.http.post('/admin/users/internal/upload-avatar', file)
      return res.data.data.id
    },

    async changeUser(_, form) {
      await api.http.patch(`/admin/users/internal/${form.id}`, form)
    },

    async internalUserToReal ( _, form ) {
      await api.http.patch(`/admin/users/internal/${form.userId}/to-real`, {phone: form.phone})
    },

    async delete(_, id) {
      const res = await api.http.delete(`/admin/users/internal/${id}/delete`)
      return res?.data
    },
  },


  namespaced: true,
}
