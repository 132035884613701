import axios from 'axios'
import {Message} from 'element-ui'
import router from '../router'

class Api {

  token = null
  http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URI,
    headers: {
      'Authorization': `Bearer ${this.getToken()}`,
    },
  })

  constructor() {
    this.setInterceptors()
  }


  async setToken(token) {
    localStorage.setItem('token', token)

    this.http.defaults.headers = {
      'Authorization': 'Bearer ' + token,
    }
  }

  setInterceptors() {
    this.http.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.statusText === 'Unauthorized') {
          if (router.app.$route.name !== 'Login') {
            router.push({name: 'Login'})
          }
        } else {
          // this.errorHandler(error)
          return Promise.reject(error)
        }
      },
    )
  }

  errorHandler(e) {
    const res = e?.request?.response
    let message
    if (res) {
      const response = JSON.parse(res)
      if (response.errors) {
        const errorsArray = Object.values(response?.errors)
        message = `${response.message ? response.message : ''}\n${errorsArray.map(i => i.join('\n')).join('\n')}`
      } else {
        message = response.message
      }
    } else {
      message = e.message
    }
    Message.error(message)
  }

  clearToken() {
    localStorage.removeItem('token')
    this.http.defaults.headers = {
      'Authorization': null,
    }
  }

  getToken() {
    return localStorage.getItem('token')
  }
}

export default new Api
