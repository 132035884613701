import ListPage from '@/pages/internal/users/ListPage';
import ViewPage from '@/pages/internal/users/ViewPage';

export default [
  {
    path: '/internal/users',
    name: 'InternalUsers',
    component: ListPage,
  },

  {
    path: '/internal/users/:id',
    name: 'InternalUser',
    component: ViewPage,
  },

]
