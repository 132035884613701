<template>
  <div v-if="list">
    <el-table
      class="archive-feed"
      :data="list.data"
    >
      <el-table-column label="Изображение" prop="image_links" width="100">
        <template slot-scope="scope">
          <img alt="Изображение" class="image" :src="scope.row.image_links[0]">
        </template>
      </el-table-column>

      <el-table-column label="Название мероприятия" prop="title" />

      <el-table-column
        label="Название заведения"
        prop="place_name"
        width="160"
      />

      <el-table-column
        label="Дата проведения"
        prop="date"
        width="160"
      />

      <el-table-column
        label="Время проведения"
        prop="time"
        width="160"
      />

      <el-table-column
        label="Тип мероприятия"
        prop="event_type.title"
        width="160"
      />

      <el-table-column
        fixed="right"
      >
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--            circle-->
          <!--            icon="el-icon-edit"-->
          <!--            size="small"-->
          <!--            type="primary"-->
          <!--            @click="restoreBeforeUpdate(scope.row.id)"-->
          <!--          />-->

          <el-button
            circle
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="removeEvent(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'ArchiveEventsList',
  data() {
    return {
      removeConfirmation: false,
      restoreConfirmation: false,
    }
  },
  computed: {
    ...mapState('events', {list: state => state.events}),
  },
  methods: {
    ...mapActions('events', ['deleteEvent', 'restoreEvent']),

    async removeEvent(id) {
        try {
          this.removeConfirmation = await this.$confirm('Вы действительно хотите удалить это мероприятие?', {
            cancelButtonText: 'Нет',
            confirmButtonText: 'Да',
            type: 'warning',
            center: true,
          })
        } catch (e) {
          console.error(e)
        }

        if (!this.removeConfirmation) {
          return
        }
        try {
          await this.deleteEvent(id)
          await this.$message.success('Мероприятие успешно  удалено!');
        } catch (e) {
          await this.$message.error('Мероприятие НЕ удалось удалить!)');
        }
    },
    // async restoreBeforeUpdate(id) {
    //   try {
    //     try {
    //       this.restoreConfirmation = await this.$confirm('Вы действительно хотите восстановить и изменить удаленное мероприятие?', {
    //         cancelButtonText: 'Нет',
    //         confirmButtonText: 'Да',
    //         type: 'warning',
    //         center: true,
    //       })
    //     } catch (e) {
    //       console.error(e)
    //     }
    //
    //     if (!this.restoreConfirmation) {
    //       return
    //     }
    //     await this.restoreEvent(id)
    //     this.$router.push({ name: 'EditEventPage', params: { id } });
    //   } catch (e) {
    //     await this.$message.error('Мероприятие НЕ удалось восстановить!)');
    //   }
    // },
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase
  white-space: pre-wrap
  height: auto
  line-height: 1.4
  padding: 5px

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0
.image
  width: 100px
  height: auto

.archive-feed
  width: 100%
  opacity: 0.5
</style>

