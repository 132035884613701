import OffersListPage from '@/pages/offers/ListPage'
import ViewOfferPage from '@/pages/offers/ViewPage'

export default [
  {
    path: '/offers/list',
    name: 'OffersList',
    component: OffersListPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['offers.list'],
    },
  },
  {
    path: '/offers/view/:id',
    name: 'ViewOffer',
    component: ViewOfferPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['offers.view'],
    },
  },
]
