import WishesListPage from '@/pages/wishes/ListPage'
import ViewWishPage from '@/pages/wishes/ViewPage'

export default [
  {
    path: '/wishes/list',
    name: 'WishesList',
    component: WishesListPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['wishes.list'],
    },
  },
  {
    path: '/wishes/view/:id',
    name: 'ViewWish',
    component: ViewWishPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['wishes.view'],
    },
  },
]
