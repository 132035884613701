import ComplaintsListPage from '@/pages/complaints/ListPage'
import ViewComplaintPage from '@/pages/complaints/ViewPage'
import ViewChat from '@/pages/complaints/ViewChat'

export default [
  {
    path: '/complaints/list',
    name: 'ComplaintsList',
    component: ComplaintsListPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['complaints.list'],
    },
  },
  {
    path: '/complaints/view/:id',
    name: 'ViewComplaint',
    component: ViewComplaintPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['complaints.view'],
    },
  },
  {
    path: '/complaints/view/:complaintId/chats/:chatId',
    name: 'ViewComplaintChat',
    component: ViewChat,
    props: true,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['complaints.view'],
    },
  },
]
