<template>
  <div>
    <PageHeader page-name="Администраторы">
      <template>
        <router-link :to="{name: 'CreateAdmin'}">
          <el-button type="primary">
            Создать Администратора
          </el-button>
        </router-link>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-loading="loading" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import List from '@/components/admin-management/List'
import MainPanel from '@/components/common/MainPanel'
import PageHeader from '@/components/common/PageHeader';


export default {
  components: {MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Администраторы',
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.getModel()
    this.loading = false
  },
  methods: {
    ...mapActions('admin-management', {
      getModel: 'fetchAdmins',
    }),
  },
}
</script>
