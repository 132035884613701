import api from '@/utils/api'

export default {
  state: () => ({
    cities: null,
    onlyCities: null,
  }),
  mutations: {
    setCities(state, payload) {
      state.cities = payload
    },
    onlyCities(state, payload) {
      state.onlyCities = payload
    },
  },
  getters: {},
  actions: {
    async fetchCities({commit}, params) {
      if(params.search){
        params.search = params.search.trim()
      }
      if (params.search === '') {
        delete params.search
      }
      const res = await api.http.get('admin/geo/cities', {params})
      commit('setCities', res?.data)
      commit('onlyCities', res?.data.data)
      return res.data.data
    },
    async fetchWishCity(_, id) {
      const res = await api.http.get(`admin/geo/cities/${id}`)
      return res?.data?.data
    },
    async isPreloadable(_, data) {
      await api.http.patch(`admin/geo/cities/${data.id}/toggle-preload`, {'is_preloadable': data.preState} )
    },

    async preloadCities() {
      try {
        const res = await api.http.get('/geo/cities/preload');
        return res?.data?.data
      } catch (e) {
        throw e.response;
      }
    },


  },
  namespaced: true,
}
