<template>
  <el-table
    :data="listOffersForWishes.data ? listOffersForWishes.data : list && list.data"
    :default-sort="{prop: 'created_at', order: 'descending'}"
    stripe
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column label="Название" prop="name">
      <template slot-scope="scope">
        <router-link class="name-wrapper" :to="{name: 'ViewOffer', params: {id: scope.row.id}}">
          <div class="preview-image" :style="`background-image:url(${scope.row.first_image})`" />
          <div>
            {{ scope.row.name }}
          </div>
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Дата создания"
      prop="created_at"
      sortable="custom"
      width="160"
    />

    <el-table-column label="Пользователь" width="250">
      <template slot-scope="scope">
        <UserCard :user="scope.row.user" />
      </template>
    </el-table-column>


    <el-table-column
      align="center"
      label="Статус"
      width="120"
    >
      <template slot-scope="scope">
        <el-tag
          class="type-tag"
          size="small"
          :type="getStatusTagType(scope.row.state)"
        >
          {{ scope.row.state && scope.row.state.replace('_', ' ') }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="70">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import UserCard from '@/components/user-card/UserCard';

export default {
  name: 'List',
  components:{UserCard},
  props: {
    listOffersForWishes: {
      type: [Object, Array],
      default: () => ([]),
    },
  },
  computed: {
    ...mapState('offers', {
      list: 'offers',
    }),
    ...mapGetters('value-objects', [
      'getStatusTagType',
    ]),
  },
  created() {
    this.fetchOffersStates()
  },
  methods: {
    ...mapActions('offers', [
      'fetchOffersStates',
    ]),
    onView(id) {
      this.$router.push({name: 'ViewOffer', params: {id}})
    },
    onSortChange(val) {
      if (val.prop === 'created_at') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'oldest' : 'newest',
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase
  white-space: pre-wrap
  height: auto
  line-height: 1.4
  padding-top: 5px
  padding-bottom: 5px

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0


</style>
