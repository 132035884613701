<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-4">
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>
      </div>

      <div class="col-md-4">
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="form.password" :placeholder="isUpdate ? '********' : ''" type="password" />
        </el-form-item>
      </div>

      <div class="col-md-4">
        <el-form-item label="Пароль снова" prop="password_confirmation">
          <el-input v-model="form.password_confirmation" :placeholder="isUpdate ? '********' : ''" type="password" />
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <el-form-item label="Роль" prop="role">
          <el-select
            v-model="form.role"
            :disabled="isItMe(form.id)"
            placeholder="Выберите роль из списка"
            @change="onSelectRole"
          >
            <el-option
              v-for="role in roles"
              :key="role.name"
              :label="role.title"
              :value="role.name"
            />
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-8">
        <el-form-item label="Права" prop="permissions">
          <el-select
            v-model="form.permissions"
            :disabled="isItMe(form.id)"
            multiple
            placeholder="Выберите права из списка"
          >
            <el-option
              v-for="permission in permissions"
              :key="permission.name"
              :label="permission.title"
              :value="permission.name"
            />
          </el-select>
        </el-form-item>
      </div>
    </div>

    <div class="text-right">
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Сохранить
      </el-button>
    </div>
  </el-form>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

const updateRoute = 'UpdateAdmin'

export default {
  name: 'CreateAdminForm',
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('Пароли не совпадают'))
      } else {
        callback()
      }
    }
    return {
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        role: null,
        permissions: [],
      },
      permissions: [],
      rules: {
        email: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        password: [
          {required: this.$route.name !== updateRoute, message: 'Поле обязательно для заполнения', trigger: 'blur'},
          {min: 8, message: 'Длина должна быть не менее 8 символов', trigger: 'blur'},
        ],
        password_confirmation: [
          {required: this.$route.name !== updateRoute, message: 'Поле обязательно для заполнения', trigger: 'blur'},
          {min: 8, message: 'Длина должна быть не менее 8 символов', trigger: 'blur'},
          {validator: validatePassword, trigger: 'blur'},
        ],
        role: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        permissions: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    ...mapState('admin-management', [
      'roles',
    ]),
    ...mapGetters('auth', [
      'isItMe',
    ]),
    isUpdate() {
      return this.$route.name === updateRoute
    },
  },
  async created() {
    this.getParamsFromRouter()
    this.fetchRoles()
    this.permissions = await this.fetchPermissions()
  },
  methods: {
    ...mapActions('admin-management', [
      'getAdmin',
      'createAdmin',
      'updateAdmin',
      'fetchRoles',
      'fetchPermissions',
    ]),
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.isUpdate) {
            await this.updateAdmin(this.form)
          } else {
            await this.createAdmin(this.form)
          }
          await this.$router.push({name: 'AdminsList'})
        } else {
          return false
        }
      })
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id
      if (id) {
        const model = await this.getAdmin(id)
        this.fillForm(model)
      }
    },
    fillForm(model) {
      this.form = {...this.form, ...model}
      this.permissions = this.roles.find(x => x.name === model.role)?.permissions
      this.form.permissions = model.permissions.map(x=>x.name)
    },
    onSelectRole(val) {
      this.form.permissions = this.roles.find(x => x.name === val).permissions.map(permissionItem => permissionItem.name)
    },
  },
}
</script>
