<template>
  <router-link class="offer-info" :to="{name: 'ViewOffer', params: {id: offer.id}}">
    <div>
      <div class="offer-info__preview-image" :style="`background-image:url(${offer.first_image})`" />
    </div>
    <div>
      {{ offer.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'OfferInfo',
  props: {
    offer: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="sass" scoped>

.offer-info
  display: flex
  align-items: center

.offer-info__preview-image
  height: 55px
  width: 55px
  border-radius: 6px
  background-size: cover
  background-position: center
  margin-right: 15px

</style>
