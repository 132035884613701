<template>
  <div class="panel panel-default filter-panel">
    <div class="filter-panel__left">
      <i class="fas fa-filter" />
    </div>
    <div class="filter-panel__right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel',
}
</script>

<style lang="sass" scoped>

.panel
  margin-bottom: 0

.filter-panel
  display: flex

.filter-form
  display: flex
  align-items: flex-end
  flex-wrap: wrap

  > *
    margin-right: 15px
    margin-top: 15px

  button
    margin-bottom: 22px


.filter-panel__left
  display: flex
  align-items: center
  justify-content: center
  font-size: 66px
  color: #f1f1f1
  padding-right: 4px
  padding-left: 15px

.filter-panel__right
  padding: 15px

::v-deep
  .el-form-item
    margin-bottom: 0
    margin-right: 20px

  .el-button
    height: 40px
    padding-top: 10px

</style>
