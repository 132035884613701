<template>
  <el-header>
    <el-dropdown>
      <span class="el-dropdown-link">
        <i class="el-icon-user" />
        <span>{{ user && user.email }}</span>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <i class="fas fa-cog" />
          Редактировать
        </el-dropdown-item>
        <el-dropdown-item @click.native="onLogout">
          <i class="fas fa-sign-out-alt" />
          Выйти
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-header>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'TheHeader',
  computed: {
    ...mapState('auth', [
      'user',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
    onLogout() {
      this.logout()
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.el-header
  background: $color-header
  display: flex
  align-items: center
  justify-content: flex-end

.el-dropdown
  height: 100%

.el-dropdown-link
  color: #aaaaaa
  display: flex
  align-items: center
  height: 100%

  &:hover
    color: #FFFFFF

  i
    margin-right: 6px
    font-size: 18px

.el-dropdown-menu__item
  color: $color-text

  &:hover
    color: $color-text !important

  a
    color: $color-text


</style>
