<template>
  <router-link class="name-wrapper" :to="{name: 'ViewContractor', params: {id: contractor.id}}">
    <UserAvatar :user="contractor.user" />
  </router-link>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar'


export default {
  name: 'ContractorCard',
  components:{UserAvatar},
  props:{
    contractor:{
      type:[Object, null],
      required:true,
    },
  },
}

</script>

<style scoped>

</style>
