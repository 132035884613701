import TranslationsPage from '@/pages/translations/TranslationsPage'
import ManagementPage from '@/pages/translations/ManagementPage'

export default [
  {
    path: '/translations/import',
    name: 'ImportTranslationFiles',
    component: ManagementPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['translations.manage'],
    },
  },
  {
    path: '/translations/:target/:category/:id',
    name: 'Translations',
    component: TranslationsPage,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['translations.edit'],
    },
  },
]
