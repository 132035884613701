<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPanel',
}
</script>

<style lang="sass" scoped>

.panel
  margin-bottom: 0
  min-height: 100px

</style>
