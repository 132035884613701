<template>
  <div>
    Dashboard
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  metaInfo: {
    title: 'Home Page',
  },
}
</script>

<style lang="sass" scoped>


</style>
