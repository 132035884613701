<template>
  <div>
    <PageHeader page-name="Мероприятия">
      <template>
        <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel>
        <router-link :to="{name: 'EventCreatePage'}">
          <el-button type="success">
            Добавить мероприятие
          </el-button>
        </router-link>
      </template>
    </PageHeader>
    <div>
      <EventsList />

      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </div>
  </div>
</template>

<script>
import EventsList from '../../components/events/EventsList.vue';
import {mapActions, mapState} from 'vuex';
import PageHeader from '../../components/common/PageHeader.vue';
import FilterPanel from '@/components/common/FilterPanel';
import Filters from '@/components/events/EventsFilters';

export default {
  name: 'AllEventsPage',
  components: {Filters, FilterPanel, PageHeader, EventsList},
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('events', ['events']),
    meta() {
      return this.events?.meta
    },
  },
  async mounted() {
    this.loading = true
    await this.getAllEvents(this.filterObject)
    this.loading = false
  },

  methods: {
    ...mapActions('events', ['getAllEvents']),
    async getEvents() {
      this.loading = true
      await this.getAllEvents(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getEvents()
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.page = 1
      this.getEvents()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
