<template>
  <div>
    <PageHeader page-name="Архив мероприятий" />
    <div>
      <ArchiveEventsList />

      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </div>
  </div>
</template>

<script>
import ArchiveEventsList from '../../components/events/ArchiveEventsList.vue';
import {mapActions, mapState} from 'vuex';
import PageHeader from '../../components/common/PageHeader.vue';

export default {
  name: 'EventsArchivePage',
  components: {PageHeader, ArchiveEventsList},
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('events', ['events']),
    meta() {
      return this.events?.meta
    },
  },
  async mounted() {
    this.loading = true
    await this.getArchiveEvents(this.filterObject)
    this.loading = false
  },

  methods: {
    ...mapActions('events', ['getArchiveEvents']),
    async getEvents() {
      this.loading = true
      await this.getArchiveEvents(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getEvents()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
