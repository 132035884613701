import api from '@/utils/api'

export default {
  state: () => ({
    regions:[],
  }),
  mutations: {
    setRegions(state, payload) {
      state.regions = payload
    },
  },
  getters: {
    regionsTransformed: state => {
      return state.regions.map(item => {
        return {
          key: item,
          label: item,
        }
      })
    },
  },
  actions: {
    async fetchRegions({commit}) {

      const res = await api.http.get('admin/geo/regions')
      commit('setRegions', res?.data.data)
    },
  },
  namespaced: true,
}
