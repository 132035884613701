<template>
  <div v-if="internalUser">
    <PageHeader link-name="Назад" link-path="InternalUsers" :page-name="internalUserName">
      <template>
        <el-avatar
          v-if="internalUser.avatar"
          shape="square"
          :size="50"
          :src="internalUser.avatar.path"
        />
      </template>
    </PageHeader>
    <el-card class="box-card">
      <el-descriptions border :column="1">
        <el-descriptions-item label="Пользователь">{{ internalUser.username }}</el-descriptions-item>
        <el-descriptions-item label="Имя">{{ internalUser.first_name }}</el-descriptions-item>
        <el-descriptions-item label="Фамилия">{{ internalUser.last_name }}</el-descriptions-item>
        <el-descriptions-item label="Тип акаунта">{{ internalUser.account_type }}</el-descriptions-item>
        <el-descriptions-item label="Тип пакета">{{ internalUser.package_type }}</el-descriptions-item>
        <el-descriptions-item label="Рейтинг">
          {{ internalUser.rating }}
        </el-descriptions-item>
        <el-descriptions-item label="Дата регистрации">{{ internalUser.registration_date }}</el-descriptions-item>
        <el-descriptions-item v-if="internalUser.achievements.length > 0" label="Активные ачивки">
          <el-tag v-for="achievement in internalUser.achievements" :key="achievement.title" type="success">{{ achievement.title }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="Удаление пользователя">
          <el-button type="danger" @click="deleteInternalUser">
            <i class="fas fa-remove" /> Удалить внутреннего пользователя
          </el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'ViewPage',
   components:{PageHeader},
  data(){
    return {
      internalUser: null,
    }
  },

  computed:{
  internalUserName () {
    return `Внутренний пользователь: ${this.internalUser?.username}`
  },
},

 async created() {
   this.internalUser = await this.fetchInternalUser(this.$route.params.id);
   console.log('this.internalUser', this.internalUser)
  },

  methods: {
    ...mapActions('internalUsers', [
      'fetchInternalUser',
      'delete',
    ]),

    async deleteInternalUser() {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Ви впевнені, що хочете видалити внутрішнього користувача та усі його публікації?', {
          cancelButtonText: 'Ні',
          confirmButtonText: 'Так',
          type: 'warning',
          center: true,
        })
      } catch (e) {
      }
      if (!confirmation) {
        return
      }
      try {
        let result = await this.delete(this.internalUser.id)
        this.$message({
          showClose: true,
          message: result.message,
          type: 'success',
          duration: 0,
        });
      } catch (e) {
        console.log(e)
        this.$message.error('Не вдалося видалити внутрышнього користувача')
      }
      await this.$router.push({name: 'InternalUsers'});
    },
  },
}

</script>

