const defaultErrorsObject = [
  {
    type: Object,
    default() {
      return {};
    },
    value() {
      return {};
    },
  },
];
export default {
  data() {
    return {
      errors: { ...defaultErrorsObject },
    };
  },
  methods: {
    $_ErrorMixin_hasError(fieldName) {
      return !!(
        typeof this.errors[fieldName] !== 'undefined' &&
        this.errors[fieldName].length
      );
    },
    $_ErrorMixin_getError(fieldName) {
      if (!this.$_ErrorMixin_hasError(fieldName)) {
        return null;
      }
      return this.errors[fieldName][0];
    },

    $_ErrorMixin_clearErrors() {
      this.errors = { ...defaultErrorsObject };
    },

    $_ErrorMixin_handleError(error) {
      if (!error) {
        return;
      }
      if (error?.data?.errors) {
        this.errors = error.data.errors;
      } else if (error?.response?.data?.errors) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
