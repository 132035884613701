<template>
  <el-drawer
    direction="ltr"
    size="50%"
    :title="formTitle"
    :visible.sync="isDrawerOpen"
  >
    <div v-if="eventType">
      <el-card class="box-card">
        <el-descriptions border :column="1">
          <el-descriptions-item label="Название на украинском">{{ eventType.title }}</el-descriptions-item>
          <el-descriptions-item label="Название на русском">{{ eventType.title_ru }}</el-descriptions-item>
          <el-descriptions-item label="Сео заголовок на украинском">{{ eventType.seo_title }}</el-descriptions-item>
          <el-descriptions-item label="Сео заголовок на русском">{{ eventType.seo_title_ru }}</el-descriptions-item>
          <el-descriptions-item label="Сео текст на украинском">{{ eventType.seo_text }}</el-descriptions-item>
          <el-descriptions-item label="Сео текст на русском">{{ eventType.seo_text_ru }}</el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'EventTypeView',
  data () {
    return {
      formTitle: '',
      eventType: null,
      isDrawerOpen: false,
    }
  },
  methods: {
    closeDrawer() {
      this.isDrawerOpen = false;
      this.eventType = null;
    },
    async openDrawer(selectedEventType = null) {
      this.eventType = {...selectedEventType}
      this.isDrawerOpen = true
      this.formTitle = `Просмотр ${selectedEventType.title}`
    },

  },

}
</script>

<style lang="scss" scoped>

</style>
