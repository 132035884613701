<template>
  <div class="auth-wrapper">
    <div class="auth-wrapper__inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.auth-wrapper
  width: 100%
  display: flex
  justify-content: center
  background: $color-sidebar
  min-height: 100vh

.auth-wrapper__inner
  margin-top: 12vh

</style>
