import viewProblemTicket from '@/pages/help-center/tickets-problem/ViewPage'
import viewTicketPage from '@/pages/help-center/tickets/ViewPage'

export default [
  {
    path: '/help-center/tickets/:id',
    name: 'ViewTicketPage',
    component: viewTicketPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help-center/tickets-problem/:id',
    name: 'ViewProblemTicket',
    component: viewProblemTicket,
    meta: {
      requiresAuth: true,
    },
  },
]
