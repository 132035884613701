import TopicsPage from '../pages/topics/TopicsPage.vue';
import CreateTopicPage from '../pages/topics/CreateTopicPage.vue';
import EditTopicPage from '../pages/topics/EditTopicPage.vue';

export default [
  {
    path: '/topics',
    name: 'TopicsPage',
    component: TopicsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/topics/create',
    name: 'CreateTopicPage',
    component: CreateTopicPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/topics/edit/:id',
    name: 'EditTopicPage',
    component: EditTopicPage,
    meta: {
      requiresAuth: true,
    },
  },
]
