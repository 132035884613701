import api from '@/utils/api'

export default {
  state: () => ({
    translations: [],
    translationsCategories: [],
  }),
  mutations: {
    setTranslationsCategories(state, payload) {
      state.translationsCategories = payload
    },

    setTranslations(state, payload) {
      state.translations = payload
    },
  },
  getters: {
    getTranslationCategory(state) {
      return id => {
        return state.translationsCategories.find(x => x.id === id)
      }
    },

    getTotalSymbolCountsUa(state) {
      const total = []
      state.translations.forEach((el) =>{
        if(el.data.ua){
          total.push(el.data.ua.split(' ').join('').length)
        }
      } )
      return total.length <= 0 ? 0 : total.reduce((a, b) => a + b )
    },
    getTotalSymbolCountsRu(state) {
      const total = []
      state.translations.forEach((el) =>{
        if(el.data.ru){
          total.push(el.data.ru.split(' ').join('').length)
        }
      } )
      return total.length <= 0 ? 0 : total.reduce((a, b) => a + b )
    },
  },
  actions: {
    async fetchTranslationsCategories({commit}) {
      const res = await api.http.get('admin/translations')
      commit('setTranslationsCategories', res?.data?.data)
    },

    async fetchTranslations({commit}, id) {
      const res = await api.http.get(`admin/translations/${id}`)
      commit('setTranslations', res?.data?.data)
    },

    async cleanTranslations() {
      await api.http.delete('admin/translations')
    },

    async toggleTranslationComplete(_, {category_id, translation_id, is_complete}) {
      await api.http.post(`admin/translations/${category_id}/${translation_id}/complete`, {is_complete})
    },

    async saveTranslation(_, {category_id, translation_id, data}) {
      await api.http.post(`admin/translations/${category_id}/${translation_id}`, {data})
    },
  },
  namespaced: true,
}
