import api from '@/utils/api'

export default {

  actions: {
    async fetchInternalWish(_, id) {
      const res = await api.http.get(`/admin/wishes/internal/${id}`)
      return res?.data.data
    },

    async fetchInternalWishes(_, {user_id, state, page}) {
      const usp = new URLSearchParams({
        ...(user_id && {user_id}),
        ...(state && { state }),
        ...(page && {page}),
      });
      const url = usp.toString();
      const res = await api.http.get(`/admin/wishes/internal?${url}`)
      return res?.data
    },

    async uploadInternalImage(_, file) {
      const res = await api.http.post('/admin/wishes/internal/upload-image', file)
      return res?.data.data.id
    },

    async createInternalWish(_, form) {
      await api.http.post('/admin/wishes/internal', form)
    },

    async publishInternalWish(_, wishId) {
      await api.http.post(`/admin/wishes/internal/${wishId}/publish`)
    },

    async hideInternalWish(_, wishId) {
      await api.http.post(`/admin/wishes/internal/${wishId}/hide`)
    },

    async updateInternalWish(_, wish) {
      await api.http.patch(`/admin/wishes/internal/${wish.wishId}`,wish)
    },

  },


  namespaced: true,
}
