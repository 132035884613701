export function userCan(user, permissions) {
  if (!user) return false
  const userPermissions = user.permissions.map(x => x.name)

  return userPermissions.some(userCan => {
    let res = permissions.includes(userCan)

    if (res === false) {
      const [controller, action] = userCan.split('.')

      if (action && action === '*') {
        const permissionControllers = permissions.map(x => x.split('.')[0])
        res = permissionControllers.includes(controller)
      }
    }
    return res
  })
}
