<template>
  <div>
    <PageHeader page-name="Жалобы">
      <template>
        <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-loading="loading" @refetch-data="getComplaints" />


      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import List from '@/components/complaints/List'
import MainPanel from '@/components/common/MainPanel'
import FilterPanel from '@/components/common/FilterPanel'
import Filters from '@/components/complaints/Filters'
import PageHeader from '@/components/common/PageHeader';


export default {
  components: { Filters, FilterPanel, MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Жалобы',
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
        sorted: 'DESC',
      },
    }
  },
  computed: {
    ...mapState('complaints', [
      'complaints',
    ]),
    meta() {
      return this.complaints?.meta
    },
  },
  mounted() {
    this.getComplaints()
  },
  methods: {
    ...mapActions('complaints', [
      'fetchComplaints',
    ]),
    async getComplaints() {
      this.loading = true
      await this.fetchComplaints(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getComplaints()
    },
    doFilter(filters) {
      this.filterObject = {...this.filterObject, ...filters}
      this.filterObject.page = 1
      this.getComplaints()
    },
  },
}
</script>
