<template>
  <el-dialog
    title="Добавить (НЕ изменить) номера телефона"
    :visible="visible"
    width="500px"
    @close="handleClose"
  >
    <el-form ref="form" label-width="160px" :model="formData">
      <el-form-item label="Номер телефона">
        <el-input
          v-model="formData.newPhoneNumber"
          maxlength="13"
          placeholder="+380671111111"
          @input="formatPhoneNumber"
        />
        {{ $_ErrorMixin_getError('phone') }}
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">Отмена</el-button>
      <el-button type="primary" @click="save">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import ErrorMixin from '../../utils/ErrorMixin';

export default {
  name: 'ChangePhoneNumberModal',
  mixins: [ErrorMixin],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        newPhoneNumber: this.phone,
      },
    };
  },
  watch: {
    phone() {
      this.formData.newPhoneNumber = this.phone;
    },
  },
  methods: {
    ...mapActions('clients', ['updatePhone']),
    handleClose() {
      this.formData.newPhoneNumber = this.phone
      this.$emit('closeModal');
    },
    cancel() {
      this.$emit('closeModal');
    },
    async save() {
      try {
        await this.updatePhone({phone: this.formData.newPhoneNumber, id: this.id})

        this.$emit('closeModal');
      } catch (e) {
        this.$_ErrorMixin_handleError(e)
      }
    },

    formatPhoneNumber(event) {
      console.log(event)
      let value = event;

      // Разрешаем ввод только цифр и "+" в качестве первого символа
      if (value.startsWith('+')) {
        value = '+' + value.slice(1).replace(/\D/g, '');
      } else {
        value = value.replace(/\D/g, '');
      }

      // Обновляем значение в formData
      this.formData.newPhoneNumber = value;
    },
  },
};
</script>

<!--<style scoped>-->
<!--.dialog-footer {-->
<!--  display: flex;-->
<!--  justify-content: flex-end;-->
<!--}-->
<!--</style>-->


